import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  tableCell: {
    fontSize: "16px",
    color: "#000",
    width: "20%",
    padding: "14px",
    fontFamily: "Poppins",
  },
  tableCellTwo: {
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.6)",
    padding: "14px",
    width: "20%",
    fontFamily: "Poppins",
  },
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionBtn: {
    minWidth: "0px",
    padding: "4px 2.5px",
  },
  //failed invitations
  tableCellThree: {
    fontSize: "16px",
    color: "#000",
    padding: "14px",
    width: "25%",
    fontFamily: "Poppins",
  },
  tableCellfour: {
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.6)",
    padding: "14px",
    width: "25%",
    fontFamily: "Poppins",
  },
});

export default useStyles;

import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
const SingleAdminAlertModal = ({ closeModal }) => {
  const history = useHistory();
  return (
    <Card
      elevation={0}
      sx={{
        height: "200px",
        width: "500px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 600 }}>
          Alert !
        </Typography>

        <Typography
          sx={{ textAlign: "center", fontWeight: 400, my: 4, fontSize: "18px" }}
        >
          As you are the <span style={{ fontWeight: 600 }}>Last Admin</span> to
          this organization, Please go to manage users to assign an admin.
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box sx={{ width: "180px" }}>
              <FilledButton
                text="Manage Users"
                submit={() => history.push("/organizations")}
                bgColor="black"
                color="white"
              />
            </Box>
            <Box sx={{ width: "120px" }}>
              <OutlinedButton text="cancel" submit={closeModal} color="black" />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SingleAdminAlertModal;

import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";

const LeaveOrganizationModal = ({
  closeModal,
  organizationDetails,
  handleSubmit,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        height: "200px",
        width: "500px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 600 }}>
          Leave Confirmation
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {organizationDetails?.name}
          </Typography>
          <Typography>BADKJ61</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box sx={{ width: "180px" }}>
              <FilledButton
                text=" Leave Organization"
                submit={() => handleSubmit()}
                bgColor="red"
                color="white"
              />
            </Box>
            <Box sx={{ width: "120px" }}>
              <OutlinedButton text="cancel" submit={closeModal} color="black" />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LeaveOrganizationModal;

import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@material-ui/core";
import Link from "@mui/material/Link";
import EditIcon from "../../assets/editIcon.png";
import DefaultImg from "../../assets/defaultimg.png";
import CircularProgress from "@mui/material/CircularProgress";
import { fget, fpatch, fdelete } from "../../API/callsApi";
import { deleteCookies } from "../../authorization";
import Header from "../Header/Header";
import useStyles from "./styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogTitle";
import classNames from "classnames";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import useAuth from "../../hooks/useAuth";
import { Formik, Form } from "formik";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Editprofile = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();
  const [open, setOpen] = useState(false);
  const [newImage, setNewImage] = useState("");
  const [showBtn, setShowBtn] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const { loadUserData } = useAuth();

  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = async () => {
    try {
      const res = await fget({
        url: "/user/",
      });
      if (res.status === 200 || res.status === 201) {
        setImage(res.data.avatar);
        setInitialValues({
          fname: res.data.first_name,
          lname: res.data.last_name,
          code: res.data.phone_number?.country_code,
          phone: res.data.phone_number?.number.toString(),
          username: res.data.nickname,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const btnsave = async (values) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("first_name", values.fname);
    formData.append("last_name", values.lname);
    formData.append("nickname", values.username);
    formData.append("phone_number", `+${values.code}${values.phone}`);
    formData.append("avatar", newImage);
    let obj = {
      url: "/user/",
      data: formData,
    };
    try {
      fpatch(obj).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            setSnackValue({
              isOpen: true,
              message: `SuccessFully saved`,
              isError: false,
            });
            loadUserData();
            setLoading(false);
            setInitialValues({
              fname: res.data.first_name,
              lname: res.data.last_name,
              code: res.data.phone_number?.country_code,
              phone: res.data.phone_number?.number.toString(),
              username: res.data.nickname,
            });
            setShowBtn(false);
          }
        },
        () => {
          setLoading(false);
        }
      );
    } catch (error) {
      setLoading(false);
      setSnackValue({
        isOpen: true,
        message: "oops, something went wrong, try again",
        isError: true,
      });
    }
  };

  const btnClear = (e, values) => {
    e.preventDefault();
    values.fname = "";
    values.lname = "";
    values.code = "";
    values.phone = "";
    values.username = "";
  };

  const deleteHandler = (e) => {
    e.preventDefault();
    setOpen(false);

    let obj = {
      url: "/user/",
    };

    fdelete(obj).then((res) => {
      if (res.status) {
        localStorage.clear();
        deleteCookies();
        history.push("/");
      }
    });
  };

  return (
    <Grid>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ ...snackValue, isOpen: false })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>

      <Grid container pt={5} pb={10}>
        <Grid item container xs={4} style={{ paddingLeft: "13%" }}>
          <div
            style={{
              position: "relative",
              height: "172px",
              width: "172px",
              border: "1px solid black",
            }}
          >
            {image || newImage ? (
              <img
                src={newImage ? URL.createObjectURL(newImage) : image}
                alt="Profile"
                className={classes.imageBlock}
              />
            ) : (
              <img
                src={DefaultImg}
                alt="Profile"
                className={classes.imageBlock}
              />
            )}
            <label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  setShowBtn(true);
                  setNewImage(e.target.files[0]);
                }}
                style={{
                  display: "none",
                }}
                multiple="false"
              />
              <img
                src={EditIcon}
                alt="edit icon"
                className={classes.editIcon}
              />
            </label>
          </div>
        </Grid>
        <Grid item container xs={8}>
          <Typography>
            <span className={classes.heading}>Edit Profile</span>
            <Link
              sx={{ px: 41, color: "red", cursor: "pointer" }}
              onClick={() => setOpen(true)}
              underline="none"
              className={classes.text}
            >
              Delete Account?
            </Link>
            <BootstrapDialog
              onClose={() => setOpen(false)}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={() => setOpen(false)}
              ></BootstrapDialogTitle>
              <DialogContent dividers>
                <Typography
                  style={{
                    padding: "2rem",
                    textAlign: "center",
                  }}
                >
                  <span className={classes.text}>
                    Are you sure you want to delete?
                  </span>
                </Typography>
              </DialogContent>
              <DialogActions>
                <div>
                  <button
                    className={classNames(classes.buttonD, classes.btn)}
                    onClick={deleteHandler}
                  >
                    YES
                  </button>
                  <button
                    className={classNames(classes.button, classes.btn)}
                    onClick={() => setOpen(false)}
                  >
                    NO
                  </button>
                </div>
              </DialogActions>
            </BootstrapDialog>
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={btnsave}
            enableReinitialize={true}
          >
            {({ values, dirty, isSubmitting, handleChange }) => (
              <Form>
                <Grid noValidate sx={{ mt: 1 }}>
                  <div style={{ display: "flex" }}>
                    <label htmlFor="fname"></label>
                    <input
                      type="text"
                      className={classes.input}
                      style={{ marginRight: "2rem" }}
                      name="fname"
                      placeholder="First Name"
                      value={values.fname}
                      onChange={handleChange}
                    />
                    <label htmlFor="lname"></label>
                    <input
                      type="text"
                      className={classes.input}
                      name="lname"
                      placeholder="Last Name"
                      value={values.lname}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex" }}>
                      <label htmlFor="code"></label>
                      <select
                        type="tel"
                        className={classes.input}
                        style={{
                          width: "17.6rem",
                          marginRight: "2rem",
                        }}
                        name="code"
                        placeholder="Country Code"
                        value={`+${values.code}`}
                        onChange={handleChange}
                      >
                        {/* <option value="0" selected hidden>
                    {code}
                  </option> */}
                        <option value="+91">+91</option>
                      </select>
                      <label htmlFor="phone"></label>
                      <input
                        type="tel"
                        className={classes.input}
                        name="phone"
                        placeholder="Phone"
                        value={values.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <label htmlFor="username"></label>
                    <input
                      type="text"
                      name="username"
                      placeholder="Username"
                      className={classes.input}
                      style={{ width: "36rem" }}
                      value={values.username}
                      onChange={handleChange}
                    />
                  </div>
                  <Grid item my={1}>
                    <Link
                      href="/reset-password"
                      variant="body2"
                      style={{
                        color: "black",
                        textDecorationColor: "black",
                      }}
                    >
                      <span className={classes.text}>Reset Password</span>
                    </Link>
                  </Grid>
                  {(dirty || showBtn) && (
                    <div style={{ display: "flex" }}>
                      <button
                        type="submit"
                        className={classes.button}
                        disabled={isSubmitting}
                      >
                        {!loading && <span>Save Changes</span>}
                        {loading && (
                          <CircularProgress style={{ color: "white" }} />
                        )}
                      </button>
                      <button
                        className={classes.buttonD}
                        onClick={(e) => btnClear(e, values)}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Editprofile;

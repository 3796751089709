import React from "react";
import Signup from "./components/Signup/Signup";
import Login from "./components/Login/Login";
import ForgotPassword from "./components/Login/ForgotPassword";
import ProfileCard from "./components/ProfileCard/ProfileCard";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Editprofile from "./components/Eprofile/Editprofile";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import OrganisationsOverview from "./components/Organisations/OrganisationOverview/OrganisationsOverview";
import RouteRequiresLogin from "./RouteRequiresLogin";
import NotFound from "./components/NotFound";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import HomePageOverview from "./components/HomePage/HomePageOverview/HomePageOverview";
import UserDataProvider from "./context/UserDataProvider";
import AdminRoute from "./Routes/AdminRoute";
import InvitationsPageOverview from "./components/InvitationsPage/InvitationsPageOverview/InvitationsPageOverview";
import HotToast from "./components/ReusableComponents/HotToast/HotToast";

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  button: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

//1)check if user is logged in or not!
//2)if user is verified, if email verification is required or not
//3)if user is verified then check if user is part of any organization or not
//4)if user is not part of any organization then prompt user to join one
//5)if user is part of an organization then allow him to access sso.

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HotToast />
      <UserDataProvider>
        <BrowserRouter>
          {/* if user configuration is pending we should not register other routes of app   */}
          {/* user verification component goes here */}

          {/* ----------------------------------------------------------------------------- */}
          {/* only if user is verified and part of an org then render below component */}
          <Switch>
            <Route
              exact
              path={["/", "/login", "/login/:slug"]}
              component={Login}
            />
            <Route exact path={"/logout/:slug"}>
              <Login clearLocalStorage={true} />
            </Route>
            <Route
              exact
              path="/signup"
              component={(routerParams) => <Signup {...routerParams} />}
            />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route
              exact
              path={["/reset-password/:id", "/reset-password"]}
              component={ResetPassword}
            />
            {/* admin route where only admin can access */}
            <AdminRoute path="/home1">
              <HomePageOverview />
            </AdminRoute>
            <RouteRequiresLogin path="/home" component={HomePageOverview} />
            <RouteRequiresLogin
              path="/invitations"
              component={InvitationsPageOverview}
            />
            {/* <RouteRequiresLogin
            path="/ssoadminservices"
            component={SsoAdminServices}
          /> */}
            <RouteRequiresLogin path="/editprofile" component={Editprofile} />
            <RouteRequiresLogin path="/profile" component={ProfileCard} />
            {/* <RouteRequiresLogin path="/organizations" component={OrganisationsOverview} /> */}
            <AdminRoute path="/organizations">
              <OrganisationsOverview />
            </AdminRoute>
            {/* <RouteRequiresLogin
            path="/orgadminservices"
            component={OrgAdminServices}
          /> */}
            {/* <RouteRequiresLogin path="/ssoadminorg" component={SsoAdminOrg} /> */}
            <Route path="*" component={NotFound} />
          </Switch>
        </BrowserRouter>
      </UserDataProvider>
    </ThemeProvider>
  );
}
export default App;

import { Box, CircularProgress } from "@mui/material";
import React from "react";

const CommonLoader = ({ color, height }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: height,
      }}
    >
      <CircularProgress style={{ color: color }} />
    </Box>
  );
};

export default CommonLoader;

import React, { useState } from "react";
import { fpatch, fpost, fdelete } from "../../../API/callsApi";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-hot-toast";
//@MUI
import { Button, TableCell, TableRow, Modal } from "@material-ui/core";
import useStyles from "./OrganisationsTableActiveUsers.styles";
//icons
import Group from "../../../assets/Group.png";
import Trash from "../../../assets/Trash.png";
import Manage from "../../../assets/Manage.png";
import DefaultImg from "../../../assets/defaultimg.png";
//component
import ManageRolesModal from "../OrganisationsModal/ManageRolesModal";
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";
import UserInfoModal from "../OrganisationsModal/UserInfoModal";

const OrganisationsTableActiveUsers = ({
  userToDisplay,
  getActiveUser,
  services,
}) => {
  const classes = useStyles();
  const [isManageRolesModalOpen, setIsManageRolesModalOpen] = useState(false);
  const [iseDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isRemovingUser, setIsRemovingUser] = useState(false);
  const [isUserModalOpen, setUserModalOpen] = useState(false);
  const { currentOrganization } = useAuth();

  const handleDeleteModal = () => {
    setDeleteModalOpen(!iseDeleteModalOpen);
  };

  const handleManageRolesModal = () => {
    setIsManageRolesModalOpen(!isManageRolesModalOpen);
  };

  const handleUserModal = () => {
    setUserModalOpen(!isUserModalOpen);
  };

  const removeUser = async () => {
    const url = `/organization/${currentOrganization}/user/${userToDisplay.id}/remove/`;
    try {
      const res = await fpatch({ url });
      if (res.status === 200 || res.status === 201) {
        getActiveUser();
        toast.success("User Successfully removed");
      }
      setIsRemovingUser(false);
    } catch (error) {
      console.log(error);
      toast.error("Some error occured");
      setIsRemovingUser(false);
    }
  };

  const removeConnection = async (connectionId) => {
    const url = `/connection/${connectionId}/`;
    try {
      const res = await fdelete({ url });
      if (res.status === 200 || res.status === 201) {
        toast.success("Connection removed");
      }
    } catch (error) {
      toast.error("Some error occured while removing connection");
    }
  };

  const removeUserfromOrg = async (e) => {
    setIsRemovingUser(true);
    if (userToDisplay?.services.length == 0) {
      e.preventDefault();
      removeUser();
    } else {
      for (let i = 0; i < userToDisplay?.services.length; i++) {
        const formData = {
          service: userToDisplay?.services[i],
          user: userToDisplay.id,
        };
        try {
          let obj = {
            url: `/connection/check/`,
            data: formData,
          };
          const res = await fpost(obj);
          if (res?.status === 200 || res?.status === 201) {
            if (res?.data?.connection_id) {
              removeConnection(res?.data?.connection_id);
            }
          }
        } catch (error) {
          console.log(error);
          break;
        }
      }
      removeUser();
    }
    handleDeleteModal();
  };

  return (
    <>
      <TableRow
        key={userToDisplay.id}
        style={{ width: "100%", fontFamily: "Poppins" }}
      >
        <TableCell align="left" className={classes.tableCell}>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <div>
              {userToDisplay?.avatar ? (
                <img
                  src={userToDisplay?.avatar}
                  alt=""
                  width="36"
                  height="36"
                />
              ) : (
                <img src={DefaultImg} alt="profilepic" width="36" height="36" />
              )}
            </div>
            {userToDisplay?.first_name} {userToDisplay?.last_name}
          </div>
        </TableCell>
        <TableCell align="center" className={classes.tableCellTwo}>
          {userToDisplay?.role}
        </TableCell>
        <TableCell align="center" className={classes.tableCellTwo}>
          {userToDisplay?.email}
        </TableCell>
        <TableCell align="center" className={classes.tableCell}>
          {userToDisplay?.created_at &&
            new Date(userToDisplay?.created_at).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
        </TableCell>
        <TableCell align="right" className={classes.tableCell}>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "5px",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={handleUserModal} className={classes.actionBtn}>
              <img
                src={Group}
                alt="icons"
                height={"24px"}
                style={{ margin: "4px 2.5px" }}
              />
            </Button>
            <Button
              className={classes.actionBtn}
              onClick={handleManageRolesModal}
            >
              <img
                src={Manage}
                alt="icons"
                height={"24px"}
                style={{ margin: "4px 2.5px" }}
              />
            </Button>

            <Button className={classes.actionBtn} onClick={handleDeleteModal}>
              <img src={Trash} alt="icons" />
            </Button>
          </div>
        </TableCell>
      </TableRow>
      <div>
        <Modal
          className={classes.modal}
          open={isManageRolesModalOpen}
          onClose={handleManageRolesModal}
        >
          <ManageRolesModal
            handleManageRolesModal={handleManageRolesModal}
            services={services}
            user={userToDisplay}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.modal}
          open={iseDeleteModalOpen}
          onClose={handleDeleteModal}
        >
          <DeleteModal
            handleDelete={removeUserfromOrg}
            closeModal={handleDeleteModal}
            text1={`${userToDisplay?.first_name} ${userToDisplay?.last_name}`}
            text2={userToDisplay?.email}
            btnText={"Remove User"}
            isRemoving={isRemovingUser}
          />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.modal}
          open={isUserModalOpen}
          onClose={handleUserModal}
        >
          <UserInfoModal
            user={userToDisplay}
            closeModal={handleUserModal}
            handleManageRolesModal={handleManageRolesModal}
            handleDeleteUserModal={handleDeleteModal}
          />
        </Modal>
      </div>
    </>
  );
};

export default OrganisationsTableActiveUsers;

import React, { useEffect, useState } from "react";
import { fget, fpost } from "../../../API/callsApi";
import useAuth from "../../../hooks/useAuth";
//@MUI
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Modal,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@material-ui/core";
import { DoNotDisturb } from "@mui/icons-material";
import useStyles from "./OrganisationsOverview.styles";
//component import
import Navbar from "../../Navbar/Navbar";
import BulkUserOrganisationModal from "../OrganisationsModal/BulkUserOrganisationModal";
import NewUserOrganisationModal from "../OrganisationsModal/NewUserOrganisationModal";
import OrganisationsTools from "../OrganisationsTools/OrganisationsTools";
// import CustomPopover from "../../CommonComponent/CustomPopover";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";
import InviteNewUserModal from "../OrganisationsModal/InviteNewUserModal";
import OrganisationsTableActiveUsers from "../OrganisationsTable/OrganisationsTableActiveUsers";
import OrganisationsTableFailedInvites from "../OrganisationsTable/OrganisationsTableFailedInvites";
import OrganisationsTableSentInvite from "../OrganisationsTable/OrganisationsTableSentInvite";

const OrganisationsOverview = () => {
  const classes = useStyles();
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
  const [isUserExist, setIsUserExist] = useState(false);
  const [existingUserData, setExistingUserData] = useState({});
  const [isBulkUserModalOpen, setIsBulkUserModalOpen] = useState(false);
  const [tableView, setTableView] = useState("Active Users");
  const [services, setServices] = useState([]);
  const [userToDisplay, setUserToDisplay] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [searchQueryUser, setSearchQueryUser] = useState("");
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [invitedUserCount, setInvitedUserCount] = useState(0);
  const [failedInviteCount, setFailedInviteCount] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const { userData, currentOrganization, currentGreeting } = useAuth();

  useEffect(() => {
    setDataLoaded(false);
    setUserToDisplay([]);
    if (currentOrganization) {
      if (tableView === "Active Users") {
        getActiveUser();
      } else if (tableView === "Invitations") {
        getInvitedUser();
      } else if (tableView === "Failed Invitations") {
        getFailedInvitationUser();
      }
    }
  }, [tableView, currentOrganization]);

  useEffect(() => {
    if (currentOrganization) {
      getServices();
      getInvitedUser("count");
      getFailedInvitationUser("count");
    }
  }, [currentOrganization]);

  const getServices = async () => {
    const url = `/service/?org=${currentOrganization}`;
    try {
      const res = await fget({ url });
      setServices(res?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveUser = async () => {
    const url = `/organization/${currentOrganization}/users/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setUserToDisplay(res?.data);
        setAllUsers(res?.data);
        setDataLoaded(true);
        setActiveUserCount(res?.data.length);
      }
    } catch (error) {
      console.log(error);
      setDataLoaded(true);
    }
  };

  const getInvitedUser = async (count) => {
    const url = `/organization/${currentOrganization}/sent-invites/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        const userList = res?.data.filter(
          (user) => user?.status !== "Rejected"
        );
        if (count !== "count") {
          setUserToDisplay(userList);
        }
        setInvitedUserCount(userList.length);
        setDataLoaded(true);
      }
    } catch (error) {
      console.log(error);
      setDataLoaded(true);
    }
  };

  const getFailedInvitationUser = async (count) => {
    const url = `/organization/${currentOrganization}/sent-invites/?status=${"Rejected"}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        if (count !== "count") {
          setUserToDisplay(res?.data);
        }
        setFailedInviteCount(res?.data.length);
        setDataLoaded(true);
      }
    } catch (error) {
      console.log(error);
      setDataLoaded(true);
    }
  };

  const handleCheckUserExist = async (email) => {
    try {
      let obj = {
        url: `/user-exists/`,
        data: {
          email,
        },
      };
      const res = await fpost(obj);
      if (res.data.email) {
        setExistingUserData(res.data);
        setIsUserExist(true);
        setIsInviteUserModalOpen(false);
        setIsNewUserModalOpen(true);
      } else {
        setIsUserExist(false);
        setExistingUserData({
          email: email,
        });
        setIsNewUserModalOpen(true);
        setIsInviteUserModalOpen(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeTableView = (view) => {
    setTableView(view);
  };

  const onChangeSearchQuery = (e) => {
    setSearchQueryUser(e.target.value);
  };

  useEffect(() => {
    if (selectedRole !== "") {
      const filteredUser = allUsers.filter(
        (user) => user.role === selectedRole
      );
      setUserToDisplay(filteredUser);
    } else {
      getActiveUser();
    }
  }, [selectedRole]);

  useEffect(() => {
    if (selectedService !== "") {
      const filteredUser = allUsers.filter((user) =>
        user.services.includes(selectedService)
      );
      setUserToDisplay(filteredUser);
    } else {
      getActiveUser();
    }
  }, [selectedService]);

  return (
    <>
      <Navbar />
      <Box className={classes.root}>
        <Box className={classes.containerOne}>
          <Typography style={{ fontSize: "24px", fontFamily: "Poppins" }}>
            {currentGreeting}, {userData?.first_name}
          </Typography>
          <Button
            className={classes.addUserBtn}
            onClick={() => setIsInviteUserModalOpen(true)}
          >
            Add New User
          </Button>
        </Box>
        <OrganisationsTools
          tableView={tableView}
          services={services}
          setSelectedRole={setSelectedRole}
          selectedRole={selectedRole}
          selectedService={selectedService}
          setSelectedService={setSelectedService}
          onChangeSearchQuery={onChangeSearchQuery}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "16%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "15px",
            }}
          >
            <List
              disablePadding
              style={{
                width: "100%",
                border: "1px solid #000",
                fontSize: "16px",
                fontFamily: "Poppins",
              }}
            >
              <ListItem
                button={true}
                style={{
                  padding: "10px",
                  height: "40px",
                  backgroundColor:
                    tableView === "Active Users" ? "#000" : "#fff",
                  color: tableView === "Active Users" ? "#fff" : "#000",
                }}
                onClick={() => changeTableView("Active Users")}
              >
                <ListItemText>Active Users</ListItemText>
                <ListItemSecondaryAction
                  style={{
                    color: tableView === "Active Users" ? "#fff" : "#000",
                  }}
                >
                  {activeUserCount}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider
                variant="middle"
                component="li"
                style={{ backgroundColor: "#000" }}
              />
              <ListItem
                button={true}
                style={{
                  padding: "10px",
                  height: "40px",
                  backgroundColor:
                    tableView === "Invitations" ? "#000" : "#fff",
                  color: tableView === "Invitations" ? "#fff" : "#000",
                }}
                onClick={() => changeTableView("Invitations")}
              >
                <ListItemText>Invitations</ListItemText>
                <ListItemSecondaryAction
                  style={{
                    color: tableView === "Invitations" ? "#fff" : "#000",
                  }}
                >
                  {invitedUserCount}
                </ListItemSecondaryAction>
              </ListItem>
              <Divider
                variant="middle"
                component="li"
                style={{ backgroundColor: "#000" }}
              />
              <ListItem
                button={true}
                style={{
                  padding: "10px",
                  height: "40px",
                  backgroundColor:
                    tableView === "Failed Invitations" ? "#000" : "#fff",
                  color: tableView === "Failed Invitations" ? "#fff" : "#000",
                }}
                onClick={() => changeTableView("Failed Invitations")}
              >
                <ListItemText>Failed Invitations</ListItemText>
                <ListItemSecondaryAction
                  style={{
                    color: tableView === "Failed Invitations" ? "#fff" : "#000",
                  }}
                >
                  {failedInviteCount}
                </ListItemSecondaryAction>
              </ListItem>
            </List>
          </div>
          <div style={{ width: "77%" }}>
            <Box className={classes.tableHeader}>
              <div
                style={{ width: "20%", textAlign: "left", marginLeft: "16px" }}
              >
                {tableView === "Invitations" ? "Mail-ID" : "User Name"}
              </div>
              <div style={{ width: "20%", textAlign: "center" }}>
                {tableView === "Failed Invitations" ? "Sent Date" : "User Role"}
              </div>
              {tableView === "Active Users" && (
                <div style={{ width: "20%", textAlign: "center" }}>Mail-ID</div>
              )}
              <div style={{ width: "20%", textAlign: "center" }}>
                {tableView === "Invitations"
                  ? "Creation Date"
                  : tableView === "Failed Invitations"
                  ? "Mail-ID"
                  : "Joining Date"}
              </div>
              <div
                style={{
                  width: "20%",
                  textAlign: "right",
                  marginRight: "25px",
                }}
              >
                {tableView === "Failed Invitations" ? "Cancelled On" : "Action"}
              </div>
            </Box>
            <TableContainer className={classes.tableContainer}>
              <Table aria-label="customized table">
                <TableBody style={{ width: "100%" }}>
                  {isDataLoaded ? (
                    userToDisplay && userToDisplay.length !== 0 ? (
                      userToDisplay
                        ?.filter((search) => {
                          if (searchQueryUser === undefined) {
                            return search;
                          } else if (searchQueryUser === "") {
                            return search;
                          } else if (
                            search?.first_name
                              ?.toLowerCase()
                              .includes(searchQueryUser.toLowerCase())
                          ) {
                            return search;
                          } else if (
                            search?.last_name
                              ?.toLowerCase()
                              .includes(searchQueryUser.toLowerCase())
                          ) {
                            return search;
                          }
                          return null;
                        })
                        .map((eachUser) =>
                          tableView === "Active Users" ? (
                            <>
                              <OrganisationsTableActiveUsers
                                userToDisplay={eachUser}
                                searchQueryUser={searchQueryUser}
                                getActiveUser={getActiveUser}
                                services={services}
                              />
                            </>
                          ) : tableView === "Invitations" ? (
                            <OrganisationsTableSentInvite
                              userToDisplay={eachUser}
                              getInvitedUser={getInvitedUser}
                              searchQueryUser={searchQueryUser}
                            />
                          ) : (
                            tableView === "Failed Invitations" && (
                              <OrganisationsTableFailedInvites
                                userToDisplay={eachUser}
                                searchQueryUser={searchQueryUser}
                              />
                            )
                          )
                        )
                    ) : (
                      <div
                        style={{
                          border: "1px solid green",
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                          padding: "20px 0px",
                        }}
                      >
                        <DoNotDisturb
                          style={{
                            height: "20px",
                            width: "20px",
                            marginLeft: "45%",
                          }}
                        />
                        <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                          No Data Found
                        </p>
                      </div>
                    )
                  ) : (
                    <div className={classes.loaderContainer}>
                      <CommonLoader />
                    </div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </Box>
      <div>
        <Modal
          className={classes.modalContainer}
          open={isNewUserModalOpen}
          onClose={() => setIsNewUserModalOpen(false)}
        >
          <NewUserOrganisationModal
            setIsNewUserModalOpen={setIsNewUserModalOpen}
            services={services}
            getInvitedUser={getInvitedUser}
            setTableView={setTableView}
            tableView={tableView}
            existingUserData={existingUserData}
            isUserExist={isUserExist}
          />
        </Modal>
        <Modal
          className={classes.modalContainer}
          open={isInviteUserModalOpen}
          onClose={() => setIsInviteUserModalOpen(false)}
        >
          <InviteNewUserModal handleCheckUserExist={handleCheckUserExist} />
        </Modal>
      </div>
      <div>
        <Modal
          className={classes.modalContainer}
          open={isBulkUserModalOpen}
          onClose={() => setIsBulkUserModalOpen(false)}
        >
          <BulkUserOrganisationModal
            setIsBulkUserModalOpen={setIsBulkUserModalOpen}
          />
        </Modal>
      </div>
    </>
  );
};

export default OrganisationsOverview;

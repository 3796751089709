import React from "react";

const NoOrgSvg = () => {
  return (
    <svg
      width="624"
      height="314"
      viewBox="0 0 481 241"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M297.574 213.919L441.437 151.177M311.154 80.8386L441.708 147.383"
        stroke="black"
        strokeWidth="0.5"
      />
      <path
        d="M105.04 31.1163L104.793 31.0541L26.4521 195.067L302.62 216.386L302.931 216.41L313.05 83.2683L313.07 83.0065L105.04 31.1163ZM184.374 136.592L27.5391 194.236L105.025 32.0134L184.374 136.592ZM184.834 137.085L301.492 215.673L28.377 194.591L184.834 137.085ZM184.978 136.358L105.747 31.9331L311.753 83.3181L184.978 136.358ZM302.374 215.517L185.529 136.803L312.39 83.7268L302.374 215.517Z"
        fill="black"
      />
      <path
        d="M348.086 86.1677C347.836 92.4817 346.062 98.6415 342.914 104.121C339.766 109.6 335.338 114.235 330.009 117.631C324.68 121.026 318.608 123.081 312.312 123.62C306.016 124.159 299.683 123.165 293.855 120.724C291.121 119.578 288.529 118.122 286.128 116.383C281.54 113.071 277.721 108.807 274.933 103.882V103.878C271.961 98.6111 270.25 92.7266 269.935 86.6869C269.62 80.6471 270.71 74.6166 273.119 69.0692C275.528 63.5218 279.191 58.6085 283.819 54.7155C288.448 50.8225 293.916 48.0557 299.794 46.6326C305.672 45.2094 311.8 45.1687 317.697 46.5135C323.593 47.8584 329.098 50.5522 333.777 54.3834C338.457 58.2146 342.185 63.0787 344.668 68.5936C347.15 74.1085 348.32 80.1239 348.086 86.1674L348.086 86.1677Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M348.086 86.1677C347.836 92.4817 346.062 98.6415 342.914 104.121C339.766 109.6 335.338 114.235 330.009 117.631C324.68 121.026 318.608 123.081 312.312 123.62C306.016 124.159 299.683 123.165 293.855 120.724C291.121 119.578 288.529 118.122 286.128 116.383C281.54 113.071 277.721 108.807 274.933 103.882V103.878C271.961 98.6111 270.25 92.7266 269.935 86.6869C269.62 80.6471 270.71 74.6166 273.119 69.0692C275.528 63.5218 279.191 58.6085 283.819 54.7155C288.448 50.8225 293.916 48.0557 299.794 46.6326C305.672 45.2094 311.8 45.1687 317.697 46.5135C323.593 47.8584 329.098 50.5522 333.777 54.3834C338.457 58.2146 342.185 63.0787 344.668 68.5936C347.15 74.1085 348.32 80.1239 348.086 86.1674L348.086 86.1677Z"
        fill="#367CFF"
      />
      <path
        d="M318.248 54.8616C318.248 54.8616 327.6 54.9761 324.376 67.8638L323.497 67.9068L321.715 67.9936"
        fill="#0C0C0C"
      />
      <path
        d="M307.804 81.3855C307.847 81.3814 308.02 89.4201 317.363 89.0823C323.832 88.8495 326.397 82.0955 326.546 82.1011C325.856 81.9265 324.693 81.6653 324.391 81.4891C323.396 80.9043 322.162 80.2877 321.911 78.3025C321.766 77.1583 322.312 71.3551 322.258 70.2013L312.272 72.0268C312.316 74.4615 313.066 80.1302 309.633 80.9952C309.062 81.1384 308.448 81.2663 307.804 81.3855Z"
        fill="#E98862"
      />
      <path
        opacity="0.52"
        d="M311.87 78.7773C312.045 78.2835 312.165 77.772 312.228 77.2521C312.383 76.0661 312.407 74.8668 312.302 73.6754C314.324 73.4819 316.252 72.7249 317.867 71.4904C318.024 72.1751 318.92 75.1825 318.614 75.8169C318.038 77.0165 315.475 78.9173 311.87 78.7773Z"
        fill="#0C0C0C"
      />
      <path
        d="M314.137 77.3568C318.052 77.0767 322.774 74.6934 322.938 69.3886L323.087 64.6029C323.251 59.2991 320.597 54.9131 315.604 54.7587C310.61 54.6031 306.745 58.7883 306.581 64.0922L306.515 66.2109C306.505 66.939 306.545 67.667 306.634 68.3897C306.856 70.3009 307.2 73.2068 308.458 75.1911C309.646 77.0642 311.636 77.5352 314.137 77.3568Z"
        fill="#E98862"
      />
      <path
        d="M322.578 70.996C324.096 71.0427 325.376 69.4572 325.423 67.9398C325.47 66.4224 324.266 65.5471 322.749 65.5001L321.935 66.4927L321.273 70.9556L322.578 70.996Z"
        fill="#E98862"
      />
      <path
        d="M306.836 69.9915C307.11 70.3166 307.461 70.5679 307.857 70.7226C308.253 70.8774 308.682 70.9305 309.104 70.8774C310.006 70.7638 310.605 71.1342 311.358 71.0598C311.991 70.9975 312.1 70.7052 313.18 70.6414C317.347 70.3946 320.474 70.1319 320.677 64.903L323.328 62.5477C323.328 62.5477 324.513 77.0375 316.676 78.9636C311.86 80.1464 307.343 79.714 306.763 75.1911C306.291 71.5161 306.836 69.9915 306.836 69.9915Z"
        fill="#0C0C0C"
      />
      <path
        d="M308.811 66.9655C309.151 66.9655 309.426 66.69 309.426 66.3501C309.426 66.0102 309.151 65.7347 308.811 65.7347C308.471 65.7347 308.195 66.0102 308.195 66.3501C308.195 66.69 308.471 66.9655 308.811 66.9655Z"
        fill="#0C0C0C"
      />
      <path
        d="M314.308 66.851C314.648 66.851 314.923 66.5755 314.923 66.2356C314.923 65.8957 314.648 65.6202 314.308 65.6202C313.968 65.6202 313.692 65.8957 313.692 66.2356C313.692 66.5755 313.968 66.851 314.308 66.851Z"
        fill="#0C0C0C"
      />
      <path
        d="M321.114 68.5286C321.114 68.5286 320.738 61.013 318.47 60.5078C316.96 60.1713 313.244 62.2549 309.851 61.5433C308.366 61.2321 306.945 61.5745 306.789 62.276C306.726 62.5537 305.552 60.0913 307.94 57.0951C309.842 54.7096 316.025 54.0386 318.776 54.8821C326.393 57.2184 323.057 65.8362 323.057 65.8362L322.668 68.4178L321.114 68.5286Z"
        fill="#0C0C0C"
      />
      <path
        d="M324.576 62.4684C326.818 62.4684 328.636 60.6509 328.636 58.4089C328.636 56.167 326.818 54.3495 324.576 54.3495C322.334 54.3495 320.517 56.167 320.517 58.4089C320.517 60.6509 322.334 62.4684 324.576 62.4684Z"
        fill="#0C0C0C"
      />
      <g opacity="0.52">
        <path
          d="M311.433 70.8181H311.423C310.934 70.8041 310.116 70.7263 310.125 70.3605C310.134 70.0574 311.056 66.8084 311.162 66.4386C311.171 66.4069 311.192 66.38 311.221 66.3639C311.25 66.3478 311.284 66.3439 311.316 66.3529C311.347 66.3619 311.374 66.3832 311.39 66.4121C311.406 66.441 311.41 66.475 311.401 66.5068C310.992 67.9458 310.38 70.1613 310.374 70.3664C310.42 70.4542 310.923 70.5532 311.43 70.5678C311.463 70.5675 311.495 70.5802 311.518 70.6033C311.542 70.6264 311.555 70.6579 311.556 70.6909C311.556 70.7239 311.543 70.7558 311.52 70.7794C311.497 70.803 311.466 70.8165 311.433 70.8168V70.8181Z"
          fill="#0C0C0C"
        />
      </g>
      <path
        d="M309.946 64.2592C309.44 64.1095 308.904 64.0988 308.393 64.228C308.142 64.2898 307.901 64.3891 307.679 64.5225C307.46 64.6533 307.257 64.8113 307.077 64.9928C307.046 65.0208 307.027 65.06 307.025 65.1019C307.022 65.1438 307.037 65.1849 307.065 65.2161C307.093 65.2474 307.132 65.2662 307.174 65.2685C307.216 65.2708 307.257 65.2564 307.288 65.2284L307.298 65.2203C307.466 65.0688 307.652 64.939 307.852 64.8341C308.05 64.7295 308.261 64.6555 308.481 64.6143C308.914 64.5297 309.361 64.5609 309.778 64.7049L309.794 64.7108C309.824 64.7222 309.855 64.7276 309.887 64.7268C309.918 64.7259 309.949 64.7187 309.978 64.7057C310.007 64.6927 310.033 64.6741 310.054 64.651C310.076 64.6279 310.093 64.6007 310.103 64.5711C310.114 64.5414 310.119 64.5099 310.118 64.4784C310.116 64.4468 310.109 64.4159 310.095 64.3873C310.082 64.3588 310.063 64.3332 310.039 64.3121C310.016 64.291 309.988 64.2748 309.958 64.2644L309.946 64.2592Z"
        fill="#0C0C0C"
      />
      <path
        d="M313.571 64.7056C313.972 64.5214 314.415 64.4468 314.854 64.4893C315.076 64.5087 315.294 64.562 315.501 64.6471C315.71 64.732 315.908 64.843 316.09 64.9773L316.1 64.9842C316.134 65.0062 316.175 65.0145 316.215 65.0073C316.254 65 316.29 64.9778 316.314 64.9452C316.338 64.9126 316.348 64.8721 316.343 64.832C316.338 64.7919 316.318 64.7552 316.287 64.7296C316.09 64.5664 315.873 64.4288 315.642 64.3199C315.408 64.2088 315.158 64.1334 314.902 64.0961C314.381 64.0176 313.849 64.0807 313.36 64.2789C313.303 64.3036 313.258 64.35 313.234 64.4079C313.211 64.4658 313.211 64.5307 313.235 64.5884C313.259 64.6461 313.305 64.692 313.362 64.7163C313.42 64.7406 313.485 64.7412 313.543 64.718L313.554 64.7131L313.571 64.7056Z"
        fill="#0C0C0C"
      />
      <path
        d="M338.502 101.458C338.154 104.086 337.814 107.695 337.511 111.428C336.325 112.689 335.058 113.871 333.717 114.965C332.864 115.658 331.984 116.316 331.076 116.941C326.17 120.29 320.566 122.48 314.689 123.345C308.811 124.21 302.814 123.728 297.151 121.935C297.073 118.468 296.968 113.792 296.864 109.508C296.744 104.566 296.623 100.141 296.558 98.687C296.289 92.9169 294.76 89.0669 295.916 85.9188C296.262 84.987 296.818 84.1474 297.541 83.4652C300.235 80.8285 306.926 80.8886 309.61 80.9928C309.657 80.9947 308.828 87.1891 317.659 87.1144C324.149 87.0612 324.636 81.5758 324.636 81.5758C324.636 81.5758 327.835 81.9086 329.959 82.2774C333.433 82.8859 335.692 84.1602 337.45 86.0633C339.09 87.8378 340.057 89.7215 338.502 101.458Z"
        fill="#367CFF"
      />
      <path
        d="M286.585 81.6037L286.852 74.9941C286.858 74.8488 286.834 74.7037 286.781 74.5683C286.728 74.4328 286.647 74.3099 286.544 74.2074C286.44 74.1049 286.317 74.025 286.181 73.9729C286.045 73.9208 285.9 73.8976 285.755 73.9047C285.487 73.9263 285.237 74.0466 285.053 74.2421C284.869 74.4376 284.764 74.6945 284.759 74.963L284.701 76.3805L282.835 71.8414C282.636 71.3331 282.067 70.8363 281.512 71.0968C281.291 71.1831 281.107 71.3414 280.988 71.5461C280.869 71.7507 280.824 71.9895 280.858 72.2236C280.853 72.2243 280.848 72.2254 280.842 72.227C280.839 72.229 280.836 72.2313 280.832 72.2339L280.526 71.4501C280.469 71.3049 280.381 71.1743 280.267 71.0676C280.154 70.961 280.017 70.881 279.869 70.8336C279.72 70.7863 279.563 70.7726 279.409 70.7937C279.254 70.8148 279.107 70.8701 278.976 70.9555C278.773 71.0942 278.625 71.2983 278.554 71.5336C278.484 71.7689 278.497 72.0211 278.59 72.2482L278.721 72.5815C278.713 72.5898 278.707 72.5994 278.702 72.6099C278.562 72.4052 278.353 72.257 278.114 72.1919C277.874 72.1267 277.619 72.1488 277.395 72.2541C277.152 72.3769 276.965 72.5867 276.87 72.8415C276.775 73.0963 276.78 73.3775 276.884 73.6289L277.207 74.4575C277.194 74.449 277.18 74.4437 277.165 74.4419C277.149 74.4402 277.134 74.4421 277.119 74.4475C277.103 74.455 277.088 74.4671 277.078 74.4824C276.903 74.3346 276.683 74.2486 276.454 74.2377C276.225 74.2267 275.998 74.2914 275.809 74.4217C275.613 74.5628 275.471 74.7657 275.405 74.9977C275.339 75.2297 275.353 75.4772 275.446 75.7001L278.177 82.6907C278.56 83.6686 279.225 84.5102 280.089 85.1074C280.952 85.7047 281.974 86.0304 283.024 86.0429C283.93 86.0536 284.804 85.711 285.46 85.0877C286.117 84.4644 286.505 83.6097 286.542 82.705L286.585 81.6037Z"
        fill="#E98862"
      />
      <g opacity="0.2">
        <path
          d="M282.682 76.726C282.658 76.7351 282.632 76.7347 282.609 76.7246C282.585 76.7146 282.567 76.6959 282.557 76.6725L280.789 72.3531C280.783 72.3412 280.78 72.3283 280.779 72.3152C280.779 72.3021 280.781 72.289 280.786 72.2767C280.791 72.2645 280.798 72.2534 280.807 72.244C280.816 72.2346 280.827 72.2273 280.839 72.2223C280.852 72.2173 280.865 72.2149 280.878 72.2151C280.891 72.2153 280.904 72.2182 280.916 72.2236C280.928 72.229 280.939 72.2368 280.947 72.2465C280.956 72.2562 280.963 72.2676 280.967 72.28L282.736 76.5993C282.741 76.6111 282.744 76.6236 282.744 76.6363C282.744 76.649 282.741 76.6616 282.736 76.6733C282.731 76.685 282.724 76.6957 282.715 76.7046C282.706 76.7135 282.695 76.7206 282.683 76.7254L282.682 76.726Z"
          fill="#0C0C0C"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M280.801 77.5587C280.777 77.5679 280.751 77.5675 280.727 77.5574C280.704 77.5473 280.686 77.5284 280.676 77.5049L278.702 72.6844C278.693 72.6609 278.693 72.6346 278.703 72.6113C278.713 72.588 278.732 72.5696 278.755 72.5599C278.778 72.5515 278.804 72.5522 278.827 72.562C278.85 72.5717 278.869 72.5897 278.879 72.6125L280.853 77.433C280.863 77.4567 280.863 77.4833 280.853 77.507C280.843 77.5306 280.824 77.5493 280.801 77.559V77.5587Z"
          fill="#0C0C0C"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M278.764 78.4724C278.74 78.4816 278.714 78.4811 278.691 78.471C278.667 78.4609 278.649 78.4421 278.639 78.4185L277.065 74.5735C277.055 74.5498 277.055 74.5233 277.065 74.4997C277.075 74.476 277.094 74.4573 277.118 74.4476C277.141 74.4379 277.168 74.438 277.191 74.4479C277.215 74.4578 277.234 74.4767 277.243 74.5004L278.819 78.3444C278.829 78.3682 278.829 78.3948 278.819 78.4184C278.809 78.442 278.79 78.4608 278.766 78.4705L278.764 78.4724Z"
          fill="#0C0C0C"
        />
      </g>
      <path
        d="M308.466 91.9661L292.38 116.698C291.795 117.597 291.011 118.349 290.089 118.898C288.718 118.142 287.395 117.302 286.128 116.384C283.426 114.434 280.985 112.147 278.863 109.579L275.484 83.3819L289.006 81.2152L290.548 93.825L296.726 84.3275L308.466 91.9661Z"
        fill="#367CFF"
      />
      <path
        d="M310.352 72.1675C311.827 72.2655 313.294 71.869 314.518 71.0405C314.561 71.0119 314.611 70.9961 314.662 70.9948C314.713 70.9936 314.764 71.0069 314.807 71.0332C314.851 71.0596 314.887 71.0979 314.91 71.1436C314.933 71.1894 314.942 71.2407 314.937 71.2916C314.838 72.1632 314.408 73.7662 312.579 73.9703C311.398 74.102 310.543 73.1953 310.112 72.5924C310.082 72.5507 310.065 72.5013 310.062 72.4501C310.059 72.3989 310.071 72.348 310.096 72.3034C310.122 72.2588 310.159 72.2223 310.204 72.1982C310.25 72.1741 310.301 72.1635 310.352 72.1675Z"
        fill="white"
      />
      <path
        d="M342.863 104.237C340.486 108.335 337.387 111.97 333.716 114.965C332.863 115.658 331.983 116.316 331.076 116.94L324.793 93.0385L338.942 89.3168L342.863 104.237Z"
        fill="#367CFF"
      />
      <path
        d="M331.215 116.847C331.17 116.876 331.121 116.909 331.077 116.941C331.033 116.972 330.988 117.003 330.943 117.032L326.154 98.823L326.457 98.7443L331.215 116.847Z"
        fill="#0C0C0C"
      />
      <path
        d="M296.901 110.634C296.862 110.632 296.826 110.616 296.798 110.588C296.77 110.561 296.754 110.524 296.752 110.485L296.444 103.7C296.442 103.658 296.456 103.618 296.484 103.588C296.512 103.557 296.551 103.539 296.592 103.537C296.633 103.535 296.674 103.55 296.704 103.578C296.735 103.606 296.753 103.644 296.755 103.686L297.063 110.471C297.065 110.512 297.051 110.553 297.023 110.583C296.995 110.613 296.956 110.632 296.915 110.634H296.901Z"
        fill="#0C0C0C"
      />
      <path
        d="M180.53 173.423C202.554 173.423 220.407 155.57 220.407 133.546C220.407 111.523 202.554 93.6693 180.53 93.6693C158.507 93.6693 140.653 111.523 140.653 133.546C140.653 155.57 158.507 173.423 180.53 173.423Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M220.409 133.545C220.409 141.432 218.07 149.142 213.689 155.7C209.307 162.257 203.079 167.368 195.793 170.386C188.506 173.405 180.488 174.194 172.753 172.656C165.018 171.117 157.913 167.319 152.336 161.742C146.759 156.165 142.961 149.06 141.422 141.325C139.884 133.59 140.674 125.572 143.692 118.285C146.71 110.999 151.821 104.771 158.379 100.389C164.936 96.0076 172.646 93.6689 180.533 93.6689C185.775 93.6495 190.969 94.6677 195.816 96.6648C200.662 98.6619 205.066 101.598 208.773 105.305C212.48 109.012 215.416 113.416 217.413 118.262C219.41 123.109 220.429 128.303 220.409 133.545Z"
        fill="#367CFF"
      />
      <path
        d="M162.876 110.026C162.876 110.026 162.475 116.045 161.121 118.23C157.222 124.517 156.092 131.59 159.997 135.287C163.901 138.983 188.805 139.034 190.692 130.534C192.279 123.378 188.872 122.608 188.042 116.705C187.667 114.034 186.635 108.434 186.635 108.434"
        fill="#0A0A0A"
      />
      <path
        d="M184.245 129.286C184.202 129.281 183.726 137.196 174.53 136.508C168.164 136.031 165.894 129.276 165.748 129.276C166.434 129.131 167.59 128.918 167.896 128.756C168.898 128.217 170.138 127.657 170.461 125.71C170.648 124.589 170.332 118.848 170.43 117.713L180.202 119.894C180.066 122.29 179.11 127.85 182.459 128.833C183.015 128.995 183.615 129.144 184.245 129.286Z"
        fill="#E98862"
      />
      <path
        opacity="0.5"
        d="M180.337 126.561C180.184 126.067 180.086 125.559 180.044 125.044C179.936 123.869 179.958 122.686 180.107 121.516C178.121 121.249 176.25 120.429 174.707 119.151C174.525 119.821 173.527 122.749 173.804 123.386C174.327 124.589 176.78 126.561 180.337 126.561Z"
        fill="#0C0C0C"
      />
      <path
        d="M179.073 124.095C175.22 124.468 170.236 122.926 169.203 117.796L168.271 113.166C167.239 108.036 169.096 103.334 173.927 102.362C178.758 101.39 183.206 104.821 184.238 109.953L184.651 112.002C185.047 113.971 185.947 123.43 179.073 124.095Z"
        fill="#E98862"
      />
      <path
        opacity="0.1"
        d="M176.625 117.373C177.259 117.297 177.728 116.859 177.673 116.394C177.617 115.928 177.058 115.613 176.424 115.688C175.79 115.764 175.321 116.202 175.377 116.668C175.432 117.133 175.991 117.448 176.625 117.373Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.1"
        d="M183.324 115.512C183.381 115.976 183.939 116.29 184.573 116.217C184.683 116.204 184.791 116.179 184.896 116.144C184.925 115.59 184.927 115.057 184.91 114.564C184.734 114.521 184.552 114.51 184.372 114.533C183.738 114.608 183.269 115.046 183.324 115.512Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.5"
        d="M180.522 116.947H180.531C180.727 116.906 180.92 116.854 181.11 116.792C181.393 116.698 181.763 116.541 181.713 116.285C181.654 115.992 180.219 112.999 180.055 112.659C180.04 112.631 180.014 112.61 179.984 112.6C179.953 112.59 179.921 112.593 179.892 112.606C179.863 112.62 179.841 112.644 179.829 112.674C179.818 112.704 179.819 112.737 179.831 112.766C180.443 114.043 181.429 116.137 181.469 116.333C181.438 116.425 180.968 116.605 180.482 116.703C180.451 116.71 180.424 116.728 180.406 116.754C180.389 116.781 180.382 116.813 180.387 116.844C180.392 116.875 180.408 116.903 180.434 116.923C180.459 116.942 180.49 116.951 180.522 116.948V116.947Z"
        fill="#0C0C0C"
      />
      <path
        d="M164.418 114.828L168.749 113.982C172.02 112.613 172.948 106.913 172.948 106.913C172.948 106.913 176.986 110.758 186.635 108.433C186.635 108.433 184.539 94.9625 170.291 99.0512C165.697 100.369 162.443 105.052 162.927 111.255C163.266 115.602 164.418 114.828 164.418 114.828Z"
        fill="#0A0A0A"
      />
      <path
        d="M169.821 119.298C168.353 119.594 166.847 118.263 166.552 116.794C166.258 115.326 167.284 114.277 168.752 113.982L169.706 114.813L171.084 119.044L169.821 119.298Z"
        fill="#E98862"
      />
      <path
        d="M181.099 110.272C181.466 110.012 181.896 109.854 182.344 109.815C182.569 109.793 182.796 109.806 183.017 109.852C183.241 109.897 183.457 109.97 183.662 110.07L183.669 110.072C183.691 110.081 183.717 110.082 183.739 110.073C183.762 110.064 183.78 110.047 183.791 110.025C183.801 110.003 183.803 109.977 183.795 109.954C183.787 109.931 183.771 109.912 183.75 109.9C183.533 109.785 183.302 109.699 183.063 109.642C182.822 109.583 182.574 109.561 182.327 109.576C181.827 109.604 181.343 109.765 180.927 110.043C180.91 110.054 180.896 110.067 180.886 110.083C180.875 110.099 180.867 110.116 180.863 110.135C180.859 110.154 180.859 110.173 180.863 110.192C180.867 110.211 180.874 110.229 180.885 110.244C180.896 110.26 180.91 110.274 180.926 110.284C180.942 110.294 180.96 110.301 180.979 110.305C180.998 110.308 181.017 110.307 181.036 110.303C181.054 110.299 181.072 110.291 181.087 110.279L181.091 110.277L181.099 110.272Z"
        fill="#0C0C0C"
      />
      <path
        d="M177.142 110.819C176.65 110.721 176.141 110.757 175.668 110.923C175.434 111.004 175.214 111.119 175.014 111.265C174.815 111.41 174.635 111.578 174.479 111.767C174.463 111.786 174.455 111.81 174.456 111.835C174.458 111.859 174.469 111.883 174.487 111.899C174.505 111.916 174.529 111.925 174.554 111.924C174.579 111.923 174.602 111.913 174.619 111.895L174.624 111.89C174.775 111.72 174.947 111.569 175.136 111.442C175.323 111.314 175.528 111.216 175.744 111.149C176.173 111.015 176.631 110.996 177.07 111.095L177.08 111.098C177.099 111.103 177.118 111.104 177.137 111.101C177.156 111.099 177.174 111.092 177.19 111.083C177.207 111.073 177.221 111.06 177.232 111.045C177.243 111.029 177.251 111.012 177.256 110.994C177.26 110.975 177.261 110.956 177.258 110.937C177.255 110.918 177.248 110.9 177.238 110.884C177.228 110.868 177.215 110.854 177.199 110.843C177.184 110.832 177.166 110.824 177.148 110.82L177.142 110.819Z"
        fill="#0C0C0C"
      />
      <path
        d="M168.751 113.911C171.663 112.828 172.628 110.817 172.926 109.321C173.119 108.323 173.101 107.296 172.873 106.306L172.783 106.331C173.007 107.308 173.025 108.322 172.834 109.306C172.54 110.778 171.589 112.756 168.719 113.824L168.751 113.911Z"
        fill="#0C0C0C"
      />
      <path
        d="M205.559 127.71L207.302 121.796C207.341 121.666 207.406 121.546 207.493 121.442C207.58 121.339 207.688 121.254 207.81 121.195C207.932 121.136 208.065 121.102 208.2 121.097C208.335 121.092 208.47 121.114 208.596 121.164C208.826 121.263 209.011 121.444 209.115 121.671C209.219 121.899 209.235 122.157 209.159 122.395L208.786 123.664L211.79 120.215C212.118 119.826 212.768 119.558 213.181 119.954C213.349 120.096 213.465 120.291 213.509 120.507C213.552 120.723 213.521 120.948 213.421 121.144C213.425 121.146 213.429 121.149 213.433 121.152C213.436 121.154 213.438 121.157 213.44 121.161L213.945 120.56C214.039 120.449 214.156 120.36 214.288 120.3C214.42 120.24 214.564 120.21 214.709 120.213C214.855 120.216 214.997 120.251 215.127 120.315C215.257 120.38 215.371 120.473 215.461 120.588C215.598 120.771 215.668 120.995 215.66 121.224C215.652 121.452 215.565 121.671 215.414 121.844L215.2 122.099C215.204 122.109 215.207 122.119 215.208 122.13C215.393 121.991 215.621 121.923 215.852 121.937C216.083 121.951 216.302 122.047 216.469 122.208C216.646 122.389 216.749 122.63 216.756 122.883C216.764 123.136 216.675 123.383 216.509 123.574L215.975 124.208C215.989 124.204 216.003 124.204 216.017 124.207C216.031 124.21 216.045 124.217 216.056 124.226C216.068 124.237 216.077 124.252 216.081 124.269C216.281 124.191 216.5 124.181 216.706 124.241C216.912 124.3 217.092 124.425 217.22 124.597C217.35 124.78 217.415 125.002 217.404 125.226C217.392 125.451 217.306 125.665 217.157 125.834L212.655 131.188C212.026 131.936 211.186 132.48 210.246 132.749C209.305 133.017 208.305 132.999 207.375 132.696C206.573 132.435 205.904 131.871 205.511 131.124C205.118 130.378 205.031 129.508 205.27 128.698L205.559 127.71Z"
        fill="#E98862"
      />
      <g opacity="0.2">
        <path
          d="M210.46 124.573C210.478 124.587 210.501 124.594 210.525 124.593C210.548 124.591 210.569 124.58 210.585 124.562L213.438 121.28C213.453 121.262 213.461 121.238 213.46 121.214C213.458 121.19 213.447 121.168 213.429 121.153C213.411 121.137 213.387 121.129 213.363 121.131C213.34 121.133 213.317 121.144 213.302 121.162L210.449 124.444C210.441 124.453 210.435 124.463 210.431 124.475C210.428 124.486 210.426 124.498 210.427 124.51C210.428 124.521 210.431 124.533 210.436 124.543C210.442 124.554 210.449 124.563 210.458 124.571L210.46 124.573Z"
          fill="#0C0C0C"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M211.876 125.871C211.894 125.886 211.917 125.893 211.94 125.891C211.964 125.889 211.985 125.878 212.001 125.861L215.184 122.197C215.192 122.188 215.198 122.178 215.202 122.167C215.206 122.155 215.207 122.144 215.206 122.132C215.205 122.12 215.202 122.108 215.197 122.098C215.192 122.087 215.184 122.078 215.175 122.07C215.157 122.055 215.134 122.048 215.11 122.05C215.087 122.051 215.065 122.062 215.048 122.079L211.867 125.743C211.859 125.752 211.853 125.762 211.849 125.773C211.846 125.784 211.844 125.796 211.845 125.808C211.846 125.82 211.849 125.831 211.854 125.842C211.86 125.853 211.867 125.862 211.876 125.87L211.876 125.871Z"
          fill="#0C0C0C"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M213.399 127.286C213.417 127.301 213.44 127.308 213.463 127.306C213.486 127.304 213.508 127.293 213.523 127.276L216.063 124.354C216.077 124.335 216.084 124.312 216.082 124.289C216.079 124.266 216.069 124.245 216.051 124.23C216.034 124.215 216.011 124.207 215.988 124.208C215.965 124.209 215.943 124.219 215.927 124.235L213.387 127.158C213.38 127.167 213.374 127.177 213.37 127.188C213.366 127.199 213.365 127.211 213.365 127.223C213.366 127.235 213.369 127.246 213.375 127.257C213.38 127.267 213.387 127.277 213.396 127.285L213.399 127.286Z"
          fill="#0C0C0C"
        />
      </g>
      <path
        d="M216.166 130.292L209.427 160.864C209.411 160.932 209.396 161.004 209.377 161.072C207.863 162.656 206.224 164.114 204.475 165.433C204.123 165.694 203.771 165.953 203.41 166.202C203.187 166.227 202.962 166.239 202.738 166.239C201.537 166.239 200.357 165.923 199.317 165.322C198.276 164.722 197.412 163.858 196.811 162.818L183.969 140.596L195.828 133.746L199.846 140.699L202.791 127.343L216.166 130.292Z"
        fill="#0CBC8B"
      />
      <path
        d="M195.429 144.23C195.388 144.715 195.307 145.581 195.195 146.72C194.635 152.544 193.325 165.523 192.718 171.521C185.05 173.973 176.822 174.056 169.106 171.761H169.102C163.404 170.055 158.161 167.094 153.757 163.095C153.782 157.732 153.704 151.7 153.343 147.822C152.263 136.233 153.284 134.419 154.962 132.741C155.099 132.604 155.242 132.471 155.385 132.34C157.119 130.765 159.295 129.738 162.466 129.308C164.564 129.025 167.72 128.819 167.72 128.819C167.72 128.819 167.988 134.229 174.36 134.528C183.034 134.936 182.456 128.819 182.499 128.819C184.681 128.819 189.537 128.956 192.599 130.516C192.603 130.516 192.603 130.516 192.606 130.519C193.222 130.819 193.784 131.222 194.265 131.711C197.773 135.428 196.042 137.017 195.429 144.23Z"
        fill="#0CBC8B"
      />
      <path
        d="M181.815 117.959C180.564 118.595 179.136 118.797 177.757 118.531C177.709 118.522 177.66 118.527 177.614 118.545C177.569 118.563 177.53 118.593 177.502 118.632C177.473 118.672 177.457 118.718 177.454 118.767C177.451 118.815 177.462 118.864 177.485 118.906C177.897 119.631 178.872 120.869 180.544 120.365C181.624 120.039 182.033 118.927 182.184 118.24C182.194 118.192 182.191 118.143 182.174 118.097C182.158 118.051 182.128 118.011 182.089 117.982C182.051 117.952 182.004 117.934 181.956 117.93C181.907 117.926 181.859 117.936 181.815 117.959Z"
        fill="white"
      />
      <path
        d="M181.893 112.392C182.227 112.392 182.498 112.121 182.498 111.787C182.498 111.453 182.227 111.182 181.893 111.182C181.559 111.182 181.288 111.453 181.288 111.787C181.288 112.121 181.559 112.392 181.893 112.392Z"
        fill="#0C0C0C"
      />
      <path
        d="M176.079 113.409C176.413 113.409 176.684 113.138 176.684 112.804C176.684 112.47 176.413 112.2 176.079 112.2C175.745 112.2 175.475 112.47 175.475 112.804C175.475 113.138 175.745 113.409 176.079 113.409Z"
        fill="#0C0C0C"
      />
      <path
        d="M194.091 157.286C194.087 157.286 194.084 157.286 194.08 157.286C194.055 157.282 194.033 157.269 194.018 157.25C194.003 157.23 193.997 157.205 194 157.18L195.069 146.408C195.071 146.396 195.074 146.384 195.081 146.373C195.087 146.362 195.095 146.353 195.105 146.346C195.115 146.338 195.126 146.333 195.138 146.33C195.15 146.327 195.162 146.326 195.175 146.328C195.199 146.332 195.221 146.345 195.236 146.364C195.251 146.384 195.258 146.409 195.254 146.434L194.185 157.206C194.182 157.228 194.17 157.249 194.153 157.263C194.136 157.278 194.114 157.286 194.091 157.286Z"
        fill="#0C0C0C"
      />
      <path
        d="M187.62 156.767L193.464 154.157C193.592 154.1 193.731 154.07 193.871 154.069C194.012 154.069 194.151 154.098 194.28 154.154C194.409 154.21 194.524 154.292 194.62 154.395C194.715 154.498 194.788 154.62 194.833 154.753C194.91 155.001 194.892 155.269 194.783 155.504C194.674 155.74 194.481 155.926 194.242 156.027L192.989 156.588L197.737 156.637C198.267 156.633 198.916 156.966 198.881 157.559C198.883 157.787 198.806 158.01 198.665 158.19C198.524 158.37 198.326 158.497 198.103 158.55C198.104 158.555 198.105 158.56 198.105 158.565C198.105 158.569 198.104 158.573 198.103 158.577L198.917 158.571C199.068 158.57 199.217 158.603 199.353 158.667C199.49 158.73 199.61 158.824 199.706 158.94C199.802 159.056 199.87 159.193 199.907 159.339C199.944 159.485 199.947 159.637 199.917 159.785C199.865 160.017 199.735 160.224 199.549 160.371C199.362 160.518 199.131 160.597 198.894 160.594L198.547 160.597C198.543 160.607 198.536 160.616 198.529 160.624C198.763 160.677 198.971 160.811 199.116 161.003C199.26 161.195 199.332 161.432 199.318 161.672C199.294 161.934 199.173 162.177 198.978 162.354C198.783 162.53 198.529 162.627 198.266 162.624L197.405 162.63C197.417 162.639 197.427 162.65 197.434 162.663C197.441 162.676 197.445 162.691 197.445 162.706C197.444 162.723 197.439 162.741 197.429 162.755C197.625 162.86 197.781 163.027 197.873 163.229C197.965 163.432 197.988 163.659 197.938 163.876C197.882 164.102 197.75 164.303 197.566 164.445C197.381 164.588 197.153 164.664 196.92 164.661L189.659 164.713C188.643 164.72 187.648 164.423 186.802 163.862C185.955 163.3 185.296 162.498 184.908 161.559C184.574 160.749 184.568 159.841 184.893 159.027C185.217 158.214 185.846 157.558 186.646 157.201L187.62 156.767Z"
        fill="#E98862"
      />
      <g opacity="0.2">
        <path
          d="M193.404 158.525C193.404 158.549 193.414 158.573 193.432 158.591C193.449 158.608 193.473 158.618 193.498 158.618L198.013 158.659C198.038 158.659 198.062 158.649 198.08 158.632C198.097 158.615 198.107 158.591 198.108 158.566C198.108 158.541 198.098 158.518 198.081 158.5C198.063 158.482 198.04 158.472 198.015 158.472L193.499 158.431C193.474 158.431 193.45 158.441 193.433 158.459C193.415 158.476 193.405 158.5 193.405 158.525H193.404Z"
          fill="#0C0C0C"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M193.329 160.517C193.329 160.542 193.339 160.565 193.357 160.583C193.374 160.6 193.398 160.61 193.423 160.61L198.462 160.656C198.487 160.656 198.511 160.646 198.528 160.628C198.546 160.611 198.555 160.587 198.555 160.562C198.555 160.538 198.545 160.515 198.527 160.497C198.51 160.48 198.487 160.47 198.462 160.469L193.422 160.424C193.398 160.424 193.374 160.433 193.356 160.451C193.339 160.468 193.329 160.492 193.329 160.517Z"
          fill="#0C0C0C"
        />
      </g>
      <g opacity="0.2">
        <path
          d="M193.238 162.672C193.238 162.696 193.248 162.72 193.266 162.738C193.283 162.755 193.307 162.765 193.332 162.765L197.352 162.801C197.376 162.801 197.4 162.792 197.418 162.774C197.435 162.757 197.445 162.733 197.445 162.708C197.445 162.683 197.435 162.659 197.418 162.642C197.4 162.624 197.376 162.615 197.352 162.615L193.332 162.578C193.307 162.578 193.283 162.588 193.266 162.606C193.248 162.623 193.238 162.647 193.238 162.672V162.672Z"
          fill="#0C0C0C"
        />
      </g>
      <path
        d="M189.293 167.173L169.105 171.761H169.102C163.403 170.055 158.16 167.094 153.757 163.095C153.283 162.666 152.821 162.226 152.368 161.775C151.58 160.988 150.826 160.169 150.105 159.319L152.932 136.585C153.24 134.752 154.264 133.117 155.778 132.039C157.292 130.962 159.172 130.529 161.004 130.838C162.837 131.146 164.472 132.17 165.55 133.684C166.628 135.197 167.06 137.078 166.752 138.91L164.965 160.256L187.475 155.161L189.293 167.173Z"
        fill="#0CBC8B"
      />
      <path
        d="M160.411 161.095C160.399 161.096 160.387 161.095 160.375 161.091C160.363 161.087 160.352 161.081 160.343 161.073C160.334 161.065 160.326 161.055 160.321 161.044C160.315 161.033 160.312 161.021 160.311 161.009C160.31 160.996 160.311 160.984 160.315 160.972C160.319 160.961 160.325 160.95 160.333 160.941C160.341 160.931 160.351 160.924 160.362 160.918C160.373 160.913 160.385 160.909 160.397 160.908L164.91 159.943C164.923 159.942 164.935 159.942 164.947 159.945C164.959 159.948 164.97 159.953 164.98 159.961C164.99 159.968 164.998 159.977 165.005 159.988C165.011 159.998 165.015 160.01 165.017 160.022C165.019 160.034 165.018 160.046 165.015 160.058C165.012 160.07 165.007 160.081 165 160.091C164.992 160.101 164.983 160.109 164.973 160.116C164.962 160.122 164.95 160.126 164.938 160.128L160.423 161.093C160.419 161.094 160.415 161.095 160.411 161.095Z"
        fill="#0C0C0C"
      />
      <path
        d="M189.293 167.173C189.298 167.197 189.294 167.222 189.281 167.242C189.268 167.263 189.248 167.278 189.225 167.285L169.612 171.907C169.488 171.873 169.366 171.839 169.245 171.801L189.088 167.129L187.32 155.301L164.943 160.129C164.929 160.131 164.914 160.13 164.9 160.126C164.886 160.122 164.873 160.114 164.862 160.104C164.853 160.093 164.846 160.08 164.842 160.066C164.838 160.052 164.836 160.038 164.837 160.023L166.092 147.825C166.094 147.813 166.099 147.801 166.105 147.791C166.112 147.781 166.121 147.772 166.131 147.765C166.141 147.758 166.152 147.753 166.164 147.75C166.176 147.748 166.189 147.748 166.201 147.75C166.225 147.754 166.247 147.767 166.262 147.787C166.276 147.807 166.282 147.832 166.279 147.856L165.043 159.93L187.36 155.093C187.372 155.089 187.385 155.089 187.397 155.091C187.41 155.093 187.422 155.098 187.432 155.105C187.443 155.111 187.452 155.119 187.459 155.129C187.467 155.138 187.472 155.149 187.475 155.161L189.293 167.173Z"
        fill="#0C0C0C"
      />
      <path
        d="M37.524 219.369C57.7182 219.369 74.0889 202.998 74.0889 182.804C74.0889 162.61 57.7182 146.239 37.524 146.239C17.3297 146.239 0.958984 162.61 0.958984 182.804C0.958984 202.998 17.3297 219.369 37.524 219.369Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M37.524 219.369C57.7182 219.369 74.0889 202.998 74.0889 182.804C74.0889 162.61 57.7182 146.239 37.524 146.239C17.3297 146.239 0.958984 162.61 0.958984 182.804C0.958984 202.998 17.3297 219.369 37.524 219.369Z"
        fill="#367CFF"
      />
      <path
        d="M27.4561 162.064C27.4561 162.064 27.8523 173.077 35.2027 173.583L36.4477 159.061L27.4561 162.064Z"
        fill="#0A0A0A"
      />
      <path
        d="M28.8721 175.832C31.277 175.832 33.2266 173.883 33.2266 171.478C33.2266 169.073 31.277 167.123 28.8721 167.123C26.4672 167.123 24.5176 169.073 24.5176 171.478C24.5176 173.883 26.4672 175.832 28.8721 175.832Z"
        fill="#0A0A0A"
      />
      <path
        d="M45.9103 178.694C45.8714 178.689 45.4412 185.83 37.1455 185.208C31.4025 184.778 29.355 178.685 29.2236 178.685C29.8799 178.576 30.5273 178.419 31.1606 178.215C32.0648 177.729 33.1838 177.224 33.4751 175.468C33.6432 174.456 33.3584 169.277 33.4459 168.253L42.2614 170.221C42.1369 172.384 41.2759 177.398 44.2976 178.285C44.8 178.432 45.3416 178.567 45.9103 178.694Z"
        fill="#E98862"
      />
      <path
        opacity="0.5"
        d="M42.385 176.236C42.2468 175.79 42.1578 175.331 42.1198 174.866C42.0229 173.806 42.0423 172.739 42.1773 171.684C40.3856 171.442 38.6977 170.702 37.3052 169.549C37.1415 170.154 36.2413 172.795 36.4906 173.37C36.9619 174.458 39.1759 176.236 42.385 176.236Z"
        fill="#0C0C0C"
      />
      <path
        d="M41.0023 174.311C37.5109 174.356 33.1461 172.594 32.6051 167.904L32.1171 163.671C31.5758 158.981 33.6006 154.893 38.0167 154.385C42.4326 153.876 46.1717 157.297 46.713 161.987L46.929 163.861C47.1366 165.662 47.2319 174.232 41.0023 174.311Z"
        fill="#E98862"
      />
      <path
        d="M45.6657 163.735C45.9681 163.735 46.2132 163.49 46.2132 163.188C46.2132 162.885 45.9681 162.64 45.6657 162.64C45.3633 162.64 45.1182 162.885 45.1182 163.188C45.1182 163.49 45.3633 163.735 45.6657 163.735Z"
        fill="#0C0C0C"
      />
      <path
        d="M40.069 164.375C40.3714 164.375 40.6165 164.13 40.6165 163.828C40.6165 163.525 40.3714 163.28 40.069 163.28C39.7666 163.28 39.5215 163.525 39.5215 163.828C39.5215 164.13 39.7666 164.375 40.069 164.375Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.5"
        d="M42.8446 167.996H42.8534C43.0323 167.974 43.2098 167.942 43.3853 167.901C43.6474 167.837 43.9929 167.725 43.9658 167.49C43.9347 167.223 42.8705 164.424 42.7491 164.106C42.7438 164.092 42.7359 164.08 42.7258 164.069C42.7157 164.058 42.7036 164.05 42.6901 164.044C42.6767 164.038 42.6622 164.034 42.6475 164.034C42.6328 164.033 42.6181 164.036 42.6043 164.041C42.5906 164.046 42.578 164.054 42.5673 164.064C42.5566 164.075 42.548 164.087 42.542 164.1C42.536 164.114 42.5327 164.128 42.5323 164.143C42.5319 164.158 42.5344 164.172 42.5396 164.186C42.9937 165.378 43.7224 167.336 43.7429 167.516C43.4628 167.674 43.1488 167.762 42.8275 167.774C42.7979 167.776 42.7704 167.79 42.751 167.812C42.7317 167.835 42.7221 167.864 42.7243 167.894C42.7266 167.923 42.7406 167.951 42.7631 167.97C42.7857 167.99 42.815 167.999 42.8446 167.997V167.996Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.5"
        d="M42.0552 169.948C42.7724 169.907 43.47 169.698 44.0918 169.338C44.1163 169.323 44.134 169.299 44.1412 169.271C44.1485 169.243 44.1448 169.214 44.1308 169.188C44.1169 169.163 44.0938 169.144 44.0663 169.136C44.0388 169.127 44.009 169.129 43.9832 169.142C42.6404 169.885 41.3561 169.928 40.2698 169.266C40.2479 169.252 40.2252 169.239 40.202 169.228C40.175 169.217 40.1451 169.218 40.1184 169.229C40.0917 169.24 40.0703 169.26 40.0585 169.287C40.0468 169.313 40.0457 169.343 40.0554 169.37C40.0651 169.397 40.0849 169.42 40.1108 169.433C40.1192 169.437 40.1335 169.446 40.1531 169.458C40.7242 169.807 41.3865 169.978 42.0552 169.948Z"
        fill="#0C0C0C"
      />
      <path
        d="M28.5275 164.874L32.4845 164.441C35.5283 163.458 36.793 158.402 36.793 158.402C36.793 158.402 39.5205 161.504 47.6033 160.088C47.6033 160.088 47.2902 149.667 34.9973 151.134C30.7172 151.646 27.4897 155.938 27.4564 161.55C27.4334 165.483 28.5275 164.874 28.5275 164.874Z"
        fill="#0A0A0A"
      />
      <path
        d="M33.0463 169.301C31.7042 169.457 30.4507 168.146 30.296 166.804C30.1413 165.462 31.1436 164.596 32.4858 164.442L33.281 165.261L34.2002 169.168L33.0463 169.301Z"
        fill="#E98862"
      />
      <path
        d="M43.7284 161.822C44.1242 161.603 44.5716 161.494 45.0238 161.507C45.2472 161.512 45.4685 161.55 45.6803 161.621C45.8908 161.69 46.0917 161.786 46.2782 161.905C46.2969 161.917 46.3103 161.936 46.3156 161.957C46.3208 161.979 46.3176 162.001 46.3066 162.02C46.2956 162.04 46.2776 162.054 46.2563 162.06C46.2351 162.066 46.2123 162.064 46.1926 162.054L46.187 162.051C46.0103 161.946 45.821 161.863 45.6236 161.806C45.4282 161.747 45.225 161.719 45.021 161.721C44.6152 161.723 44.2171 161.833 43.8672 162.039L43.8588 162.044C43.8443 162.053 43.8281 162.06 43.8111 162.063C43.7941 162.066 43.7766 162.066 43.7597 162.062C43.7429 162.058 43.7269 162.051 43.7129 162.041C43.6988 162.031 43.6869 162.018 43.6779 162.003C43.669 161.988 43.663 161.972 43.6605 161.955C43.658 161.938 43.659 161.92 43.6634 161.903C43.6678 161.887 43.6755 161.871 43.6861 161.857C43.6966 161.844 43.7099 161.832 43.725 161.824L43.7284 161.822Z"
        fill="#0C0C0C"
      />
      <path
        d="M40.1814 162.474C39.7945 162.351 39.3819 162.333 38.9859 162.423C38.7864 162.466 38.5947 162.54 38.4172 162.64C38.2379 162.741 38.0718 162.863 37.9229 163.005L37.9183 163.009C37.9013 163.022 37.8804 163.028 37.8594 163.026C37.8384 163.024 37.8189 163.014 37.8045 162.999C37.7901 162.984 37.7818 162.963 37.7813 162.942C37.7807 162.921 37.7879 162.901 37.8015 162.885C37.9567 162.727 38.1311 162.589 38.3207 162.474C38.5115 162.358 38.7186 162.271 38.9352 162.216C39.3731 162.103 39.8335 162.109 40.2683 162.234C40.2851 162.238 40.3008 162.246 40.3146 162.256C40.3284 162.267 40.3399 162.28 40.3485 162.295C40.357 162.31 40.3624 162.327 40.3644 162.344C40.3664 162.361 40.3648 162.379 40.3599 162.396C40.3549 162.412 40.3467 162.428 40.3356 162.441C40.3245 162.454 40.3109 162.465 40.2955 162.473C40.2801 162.481 40.2632 162.486 40.2459 162.487C40.2286 162.489 40.2112 162.486 40.1948 162.481H40.1914L40.1814 162.474Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.52"
        d="M42.1306 171.008C42.2604 171.003 42.3896 170.986 42.5166 170.959C42.5449 170.952 42.5693 170.935 42.5848 170.91C42.6002 170.885 42.6055 170.856 42.5995 170.827C42.5935 170.799 42.5766 170.774 42.5525 170.757C42.5284 170.741 42.4989 170.735 42.4702 170.74C42.2719 170.798 42.0634 170.813 41.8588 170.784C41.6542 170.755 41.4581 170.682 41.2837 170.571C41.2607 170.552 41.2311 170.543 41.2015 170.546C41.1719 170.549 41.1447 170.564 41.1259 170.587C41.1071 170.61 41.0982 170.64 41.1011 170.669C41.1041 170.699 41.1187 170.726 41.1418 170.745C41.431 170.944 41.7805 171.037 42.1306 171.008Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.1"
        d="M39.3098 168.083C39.8855 168.063 40.3402 167.704 40.3254 167.282C40.3106 166.859 39.832 166.533 39.2563 166.554C38.6806 166.574 38.2259 166.932 38.2406 167.355C38.2554 167.777 38.7341 168.103 39.3098 168.083Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.1"
        d="M45.472 166.917C45.4881 167.339 45.9665 167.664 46.5424 167.645C46.6419 167.642 46.7408 167.628 46.8375 167.604C46.9056 167.108 46.9473 166.629 46.9701 166.185C46.815 166.132 46.6515 166.109 46.4879 166.116C45.9133 166.135 45.4589 166.493 45.472 166.917Z"
        fill="#0C0C0C"
      />
      <path
        d="M58.2983 186.215C58.2734 188.709 57.4175 191.681 57.1124 195.273C57.0315 196.232 54.9738 199.005 54.675 202.09C54.2983 206.018 55.1359 212.143 55.248 214.792C49.4642 217.993 42.9216 219.567 36.315 219.348C29.7084 219.128 23.2845 217.124 17.7256 213.547C17.5762 206.743 17.3988 198.342 17.1715 196.328C16.4339 189.68 16.6331 185.475 17.6976 183.508C19.4375 180.295 22.5346 179.315 25.7748 178.873C27.7544 178.605 30.7269 178.415 30.7269 178.415C30.7269 178.415 30.979 183.511 36.9864 183.794C45.1632 184.18 44.6185 178.415 44.6589 178.415C47.6252 178.415 53.2466 179.125 55.4005 180.594C57.6852 182.147 58.3264 183.99 58.2983 186.215Z"
        fill="#FFC239"
      />
      <path
        d="M30.048 190.094L18.9547 214.302C14.6953 211.788 10.9895 208.437 8.06055 204.451L17.3143 184.256L30.048 190.094Z"
        fill="#FFC239"
      />
      <path
        d="M67.2597 204.085C65.0865 207.117 62.4636 209.8 59.4814 212.041C58.4998 212.782 57.4806 213.472 56.4279 214.108L45.042 189.076L57.7912 183.274L67.2597 204.085Z"
        fill="#FFC239"
      />
      <path
        d="M113.623 76.1398C134.648 76.1398 151.693 59.0953 151.693 38.0699C151.693 17.0445 134.648 0 113.623 0C92.5972 0 75.5527 17.0445 75.5527 38.0699C75.5527 59.0953 92.5972 76.1398 113.623 76.1398Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M113.623 76.1398C134.648 76.1398 151.693 59.0953 151.693 38.0699C151.693 17.0445 134.648 0 113.623 0C92.5972 0 75.5527 17.0445 75.5527 38.0699C75.5527 59.0953 92.5972 76.1398 113.623 76.1398Z"
        fill="#367CFF"
      />
      <path
        d="M120.985 15.2733C120.985 15.2733 123.395 12.6606 123.209 7.68417C123.065 3.83108 120.041 6.8917 120.041 6.8917C120.041 6.8917 119.91 4.11838 117.986 4.74401C116.063 5.36964 115.472 8.41501 115.472 8.41501"
        fill="#0C0C0C"
      />
      <path
        d="M107.844 6.9071C107.844 6.9071 98.2431 9.72868 104.184 22.3553L105.061 22.2563L106.84 22.0558"
        fill="#0C0C0C"
      />
      <path
        d="M121.45 35.4527C121.406 35.4471 120.921 43.5019 111.563 42.7985C105.085 42.3132 102.775 35.4403 102.627 35.4403C103.325 35.2918 104.189 35.0668 104.532 34.9893C105.279 34.8158 105.956 34.4187 106.473 33.851C106.989 33.2832 107.321 32.5717 107.423 31.811C107.612 30.6693 107.291 24.8282 107.39 23.6731L117.333 25.8924C117.195 28.3323 116.222 33.9883 119.63 34.9893C120.198 35.1552 120.809 35.3077 121.45 35.4527Z"
        fill="#E98862"
      />
      <path
        opacity="0.52"
        d="M117.473 32.6782C117.317 32.1762 117.217 31.6587 117.174 31.1349C117.065 29.9397 117.087 28.736 117.239 27.5455C115.218 27.2727 113.314 26.4384 111.743 25.1376C111.559 25.8195 110.543 28.7992 110.824 29.4473C111.356 30.6724 113.853 32.6782 117.473 32.6782Z"
        fill="#0C0C0C"
      />
      <path
        d="M115.849 30.1137C111.927 30.4663 106.865 28.8636 105.85 23.6357L104.934 18.9186C103.919 13.6894 105.842 8.91935 110.764 7.96378C115.686 7.0079 120.188 10.5317 121.203 15.7596L121.608 17.8478C121.735 18.5672 121.813 19.2945 121.84 20.0245C121.928 21.9543 122.054 24.8873 121.127 27.0555C120.251 29.103 118.355 29.8886 115.849 30.1137Z"
        fill="#E98862"
      />
      <path
        opacity="0.5"
        d="M116.441 24.9453C117.159 24.9318 117.864 24.7497 118.499 24.4136C118.513 24.4074 118.526 24.3984 118.536 24.3873C118.546 24.3761 118.554 24.363 118.559 24.3487C118.564 24.3344 118.566 24.3192 118.565 24.3041C118.564 24.289 118.56 24.2742 118.553 24.2607C118.547 24.2471 118.537 24.2351 118.525 24.2254C118.514 24.2156 118.5 24.2083 118.486 24.2038C118.471 24.1994 118.456 24.198 118.441 24.1996C118.426 24.2012 118.411 24.2058 118.398 24.2132C117.029 24.9045 115.744 24.898 114.683 24.1964C114.662 24.1816 114.64 24.1679 114.617 24.1553C114.604 24.1488 114.589 24.145 114.574 24.144C114.56 24.1431 114.545 24.145 114.531 24.1498C114.517 24.1546 114.504 24.162 114.493 24.1717C114.482 24.1815 114.473 24.1933 114.467 24.2065C114.46 24.2197 114.456 24.2341 114.455 24.2488C114.454 24.2635 114.456 24.2783 114.461 24.2922C114.466 24.3061 114.473 24.319 114.483 24.3301C114.493 24.3411 114.505 24.3502 114.518 24.3567C114.526 24.3614 114.54 24.3707 114.559 24.3835C115.117 24.7539 115.772 24.9495 116.441 24.9453Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.52"
        d="M116.476 26.0087C116.606 26.0083 116.735 25.9968 116.863 25.9744C116.878 25.9719 116.892 25.9666 116.904 25.9588C116.917 25.951 116.927 25.9408 116.936 25.9287C116.944 25.9167 116.951 25.9032 116.954 25.8888C116.957 25.8745 116.957 25.8596 116.955 25.8451C116.953 25.8306 116.947 25.8167 116.939 25.8042C116.932 25.7918 116.921 25.781 116.909 25.7725C116.897 25.764 116.884 25.7579 116.869 25.7546C116.855 25.7514 116.84 25.751 116.826 25.7534C116.625 25.8036 116.416 25.8106 116.213 25.7738C116.01 25.7369 115.816 25.6572 115.646 25.5399C115.624 25.521 115.595 25.5116 115.566 25.5137C115.537 25.5159 115.509 25.5294 115.49 25.5514C115.471 25.5734 115.461 25.6021 115.462 25.6314C115.464 25.6606 115.477 25.6881 115.498 25.708C115.779 25.9183 116.125 26.0246 116.476 26.0087Z"
        fill="#0C0C0C"
      />
      <path
        d="M120.025 19.1651C120.366 19.1651 120.642 18.8887 120.642 18.5476C120.642 18.2065 120.366 17.9301 120.025 17.9301C119.684 17.9301 119.407 18.2065 119.407 18.5476C119.407 18.8887 119.684 19.1651 120.025 19.1651Z"
        fill="#0C0C0C"
      />
      <path
        d="M114.02 19.8668C114.361 19.8668 114.637 19.5903 114.637 19.2493C114.637 18.9082 114.361 18.6317 114.02 18.6317C113.679 18.6317 113.402 18.9082 113.402 19.2493C113.402 19.5903 113.679 19.8668 114.02 19.8668Z"
        fill="#0C0C0C"
      />
      <path
        d="M106.464 25.1691C104.968 25.4595 103.445 24.0946 103.154 22.5987C102.863 21.1028 103.916 20.0426 105.412 19.7522L106.377 20.6047L107.749 24.9194L106.464 25.1691Z"
        fill="#E98862"
      />
      <path
        d="M107.522 22.4898C107.522 22.4898 106.686 14.9838 108.851 14.1191C110.293 13.543 114.309 15.0096 117.556 13.7596C118.977 13.2124 120.44 13.3238 120.708 13.9933C120.814 14.2582 121.015 10.1375 118.167 7.55306C115.9 5.49563 109.774 5.90743 107.185 7.18546C100.014 10.7242 105.164 20.1339 105.164 20.1339L105.964 22.6289L107.522 22.4898Z"
        fill="#0C0C0C"
      />
      <g opacity="0.52">
        <path
          d="M117.365 23.0864H117.375C117.857 22.993 118.656 22.7854 118.589 22.4246C118.531 22.1255 117.095 19.0552 116.931 18.7063C116.925 18.6914 116.915 18.6781 116.903 18.667C116.89 18.656 116.876 18.6474 116.861 18.6418C116.845 18.6363 116.829 18.6338 116.813 18.6345C116.796 18.6353 116.78 18.6393 116.765 18.6462C116.75 18.6532 116.737 18.663 116.726 18.6751C116.715 18.6873 116.706 18.7015 116.701 18.7169C116.695 18.7323 116.693 18.7487 116.693 18.7651C116.694 18.7815 116.698 18.7976 116.705 18.8124C117.342 20.172 118.304 22.2674 118.343 22.4713C118.312 22.5647 117.829 22.7449 117.33 22.8405C117.297 22.8454 117.267 22.8632 117.247 22.89C117.227 22.9168 117.219 22.9505 117.224 22.9835C117.228 23.0165 117.246 23.0462 117.273 23.0661C117.3 23.086 117.334 23.0944 117.367 23.0895L117.365 23.0864Z"
          fill="#0C0C0C"
        />
      </g>
      <path
        d="M117.706 15.5634C118.188 15.332 118.724 15.2352 119.257 15.2832C119.519 15.3053 119.776 15.3663 120.019 15.4644C120.26 15.56 120.487 15.6855 120.696 15.8379C120.735 15.8647 120.761 15.9056 120.771 15.9518C120.78 15.998 120.771 16.0461 120.746 16.0858C120.72 16.1255 120.68 16.1538 120.635 16.1648C120.589 16.1757 120.54 16.1685 120.5 16.1445L120.486 16.1395C120.296 16.0185 120.092 15.9218 119.879 15.8516C119.669 15.7815 119.449 15.7436 119.228 15.7392C118.792 15.7271 118.36 15.8305 117.976 16.039L117.96 16.048C117.929 16.0658 117.895 16.0773 117.859 16.0819C117.823 16.0864 117.787 16.0838 117.752 16.0744C117.718 16.0649 117.685 16.0487 117.657 16.0267C117.628 16.0047 117.605 15.9773 117.587 15.9461C117.569 15.9149 117.557 15.8805 117.553 15.8448C117.548 15.8091 117.551 15.773 117.56 15.7383C117.57 15.7036 117.586 15.6711 117.608 15.6427C117.63 15.6143 117.658 15.5905 117.689 15.5727L117.706 15.5634Z"
        fill="#0C0C0C"
      />
      <path
        d="M114.165 16.52C113.745 16.4024 113.301 16.3978 112.878 16.5067C112.663 16.5601 112.458 16.6459 112.269 16.761C112.076 16.8768 111.898 17.0164 111.74 17.1765L111.73 17.1865C111.696 17.2195 111.65 17.2379 111.602 17.2377C111.555 17.2376 111.509 17.2189 111.475 17.1856C111.442 17.1524 111.422 17.1072 111.421 17.0597C111.42 17.0123 111.437 16.9663 111.47 16.9315C111.639 16.736 111.833 16.5627 112.046 16.4158C112.261 16.2659 112.498 16.1492 112.748 16.0693C112.998 15.9869 113.259 15.9392 113.522 15.9274C113.791 15.9126 114.061 15.9361 114.324 15.9971C114.394 16.0136 114.456 16.0575 114.494 16.1192C114.532 16.1808 114.544 16.2552 114.528 16.3258C114.511 16.3965 114.467 16.4577 114.406 16.496C114.344 16.5343 114.27 16.5465 114.199 16.53L114.183 16.5256L114.165 16.52Z"
        fill="#0C0C0C"
      />
      <path
        d="M116.059 10.5903C117.127 10.1576 117.476 8.53267 116.839 6.96099C116.202 5.38932 114.82 4.46602 113.753 4.89874C112.685 5.33146 112.336 6.95634 112.973 8.52802C113.61 10.0997 114.991 11.023 116.059 10.5903Z"
        fill="#0C0C0C"
      />
      <path
        d="M134.381 43.7761C134.419 46.3565 133.41 49.2574 133.074 53.1855C133.006 53.9824 132.844 55.7379 132.629 58.0256C132.277 61.8137 131.779 67.0522 131.343 71.774C125.112 75.0449 118.096 76.5267 111.074 76.0553C104.052 75.584 97.2969 73.1778 91.5582 69.1034C91.4212 69.0007 91.2842 68.9011 91.1473 68.8046C90.7831 63.4664 90.3536 57.8638 89.9614 54.3247C89.2237 47.7167 88.2183 44.4267 88.7817 42.2043C88.8268 42.015 88.8872 41.8297 88.9622 41.6502C90.5839 37.7159 95.7477 35.9604 99.2463 35.4873C101.382 35.1978 104.594 34.9893 104.594 34.9893C104.594 34.9893 104.865 40.4923 111.348 40.7974C120.175 41.2145 119.587 34.9893 119.631 34.9893C121.059 34.9893 128.844 36.0289 130.789 37.2988C131.116 37.5105 131.427 37.7434 131.723 37.996C132.568 38.701 133.246 39.5857 133.705 40.5857C134.165 41.5856 134.396 42.6756 134.381 43.7761Z"
        fill="#383838"
      />
      <path
        d="M101.881 47.7665L91.5595 69.1033C91.4226 69.0006 91.2856 68.901 91.1487 68.8045C87.1475 65.8707 83.7542 62.1878 81.1572 57.9602L88.7831 42.2042L89.085 41.5786L101.881 47.7665Z"
        fill="#383838"
      />
      <path
        d="M100.885 50.2224L91.4971 69.0568C91.4349 69.0101 91.3757 68.9665 91.3135 68.9261L100.689 50.1259C100.702 50.0998 100.725 50.0799 100.753 50.0706C100.781 50.0613 100.812 50.0633 100.838 50.0761C100.864 50.0894 100.883 50.1122 100.892 50.1396C100.9 50.167 100.898 50.1968 100.885 50.2224Z"
        fill="white"
      />
      <path
        d="M148.482 53.3974C146.611 57.6379 143.984 61.5025 140.729 64.802L132.63 58.0259L122.343 49.4164L131.724 37.9963L131.752 37.962L148.482 53.3974Z"
        fill="#383838"
      />
      <path
        d="M132.754 58.1087C132.781 58.1074 132.806 58.0966 132.825 58.0782C132.844 58.0598 132.856 58.0352 132.858 58.0088L133.392 51.7431C133.395 51.7142 133.386 51.6855 133.367 51.6632C133.348 51.641 133.322 51.6271 133.293 51.6246C133.264 51.6225 133.235 51.6317 133.213 51.6503C133.191 51.6689 133.177 51.6954 133.174 51.7242L132.64 57.9901C132.638 58.019 132.647 58.0477 132.665 58.0698C132.684 58.092 132.711 58.1059 132.74 58.1084L132.754 58.1087Z"
        fill="white"
      />
      <path
        d="M292.884 240.993C312.938 240.993 329.195 224.736 329.195 204.682C329.195 184.629 312.938 168.372 292.884 168.372C272.83 168.372 256.573 184.629 256.573 204.682C256.573 224.736 272.83 240.993 292.884 240.993Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M256.573 204.681C256.572 211.863 258.702 218.883 262.692 224.854C266.681 230.826 272.352 235.48 278.987 238.228C285.622 240.976 292.923 241.695 299.966 240.294C307.01 238.893 313.479 235.435 318.558 230.357C323.636 225.279 327.094 218.809 328.495 211.766C329.896 204.722 329.177 197.421 326.429 190.787C323.681 184.152 319.027 178.481 313.055 174.491C307.084 170.501 300.064 168.372 292.883 168.372C288.109 168.354 283.38 169.281 278.967 171.1C274.553 172.918 270.544 175.592 267.168 178.967C263.793 182.343 261.119 186.352 259.301 190.766C257.482 195.179 256.555 199.908 256.573 204.681Z"
        fill="#367CFF"
      />
      <path
        d="M309.359 184.645C308.779 191.368 309.317 193.648 312.108 197.5C314.463 200.748 301.065 197.987 295.138 196.931L300.368 181.643C300.368 181.643 309.562 182.284 309.359 184.645Z"
        fill="#0A0A0A"
      />
      <path
        d="M288.908 181.848C287.159 188.365 287.355 190.381 283.933 193.804C281.096 196.641 292.152 197.218 298.084 198.248L298.384 182.094C298.384 182.094 289.522 179.559 288.908 181.848Z"
        fill="#0A0A0A"
      />
      <path
        d="M289.498 200.804C289.537 200.799 289.971 208.006 298.345 207.378C304.141 206.944 306.208 200.794 306.341 200.794C305.679 200.683 305.025 200.525 304.386 200.32C303.473 199.829 302.344 199.318 302.05 197.546C301.88 196.524 302.168 191.298 302.079 190.263L293.181 192.249C293.306 194.432 294.177 199.493 291.127 200.389C290.619 200.538 290.072 200.674 289.498 200.804Z"
        fill="#E98862"
      />
      <path
        opacity="0.5"
        d="M293.055 198.322C293.194 197.872 293.284 197.409 293.322 196.941C293.42 195.871 293.401 194.794 293.264 193.729C295.073 193.485 296.777 192.738 298.182 191.574C298.348 192.184 299.256 194.85 299.005 195.43C298.529 196.527 296.294 198.322 293.055 198.322Z"
        fill="#0C0C0C"
      />
      <path
        d="M294.206 196.076C297.714 196.415 302.253 195.012 303.194 190.341L304.042 186.125C304.982 181.454 303.291 177.172 298.892 176.287C294.494 175.402 290.443 178.528 289.503 183.199L289.128 185.067C288.767 186.858 287.947 195.471 294.206 196.076Z"
        fill="#E98862"
      />
      <path
        d="M308.286 187.456L304.329 187.022C296.587 185.598 294.69 179.351 294.69 179.351C294.69 179.351 291.442 183.687 288.685 182.691C288.685 182.691 289.574 172.235 301.816 174.073C306.626 174.795 309.358 178.782 309.358 184.131C309.358 188.063 308.286 187.456 308.286 187.456Z"
        fill="#0A0A0A"
      />
      <path
        opacity="0.1"
        d="M297.573 189.311C297.623 188.887 297.196 188.488 296.619 188.419C296.042 188.35 295.533 188.638 295.482 189.061C295.432 189.485 295.859 189.884 296.436 189.953C297.013 190.021 297.522 189.734 297.573 189.311Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.1"
        d="M290.337 188.261C290.285 188.684 289.777 188.971 289.199 188.903C289.099 188.891 289.001 188.868 288.906 188.836C288.879 188.332 288.877 187.847 288.892 187.398C289.052 187.358 289.219 187.348 289.383 187.369C289.958 187.437 290.385 187.837 290.337 188.261Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.5"
        d="M292.888 189.568H292.88C292.701 189.531 292.526 189.484 292.353 189.428C292.094 189.341 291.756 189.199 291.803 188.966C291.857 188.7 293.164 185.974 293.313 185.664C293.319 185.651 293.328 185.638 293.339 185.628C293.35 185.617 293.363 185.609 293.377 185.604C293.391 185.599 293.407 185.597 293.422 185.597C293.437 185.598 293.452 185.602 293.465 185.608C293.479 185.615 293.491 185.624 293.501 185.636C293.511 185.647 293.519 185.66 293.524 185.675C293.529 185.689 293.53 185.704 293.529 185.719C293.528 185.734 293.524 185.749 293.517 185.763C292.959 186.923 292.061 188.831 292.025 189.011C292.294 189.193 292.602 189.308 292.924 189.346C292.953 189.352 292.978 189.369 292.994 189.393C293.011 189.417 293.017 189.446 293.013 189.475C293.008 189.504 292.993 189.53 292.969 189.547C292.946 189.565 292.917 189.573 292.888 189.57V189.568Z"
        fill="#0C0C0C"
      />
      <path
        d="M302.633 191.709C303.969 191.978 305.341 190.766 305.61 189.429C305.879 188.092 304.944 187.137 303.607 186.868L302.738 187.625L301.483 191.478L302.633 191.709Z"
        fill="#E98862"
      />
      <path
        d="M292.364 183.49C292.199 183.373 292.019 183.279 291.829 183.21C291.636 183.137 291.435 183.092 291.23 183.075C291.025 183.055 290.818 183.067 290.617 183.109C290.414 183.15 290.216 183.217 290.03 183.308L290.024 183.31C290.004 183.317 289.982 183.317 289.962 183.309C289.942 183.3 289.926 183.285 289.917 183.266C289.907 183.246 289.906 183.224 289.912 183.204C289.918 183.183 289.932 183.166 289.95 183.154C290.148 183.05 290.358 182.971 290.576 182.919C290.795 182.866 291.021 182.846 291.246 182.86C291.701 182.885 292.142 183.032 292.522 183.286C292.549 183.306 292.568 183.335 292.574 183.369C292.58 183.402 292.572 183.437 292.553 183.465C292.534 183.493 292.505 183.512 292.472 183.519C292.439 183.526 292.404 183.519 292.376 183.501L292.372 183.499L292.364 183.49Z"
        fill="#0C0C0C"
      />
      <path
        d="M295.966 183.988C296.414 183.899 296.877 183.931 297.308 184.083C297.521 184.156 297.722 184.261 297.904 184.394C298.086 184.525 298.249 184.678 298.392 184.85C298.408 184.867 298.416 184.89 298.415 184.913C298.414 184.936 298.404 184.957 298.387 184.973C298.37 184.988 298.347 184.996 298.324 184.995C298.301 184.994 298.28 184.984 298.264 184.967L298.26 184.962C298.122 184.807 297.965 184.67 297.793 184.554C297.623 184.438 297.436 184.348 297.239 184.288C296.848 184.165 296.432 184.148 296.032 184.239L296.023 184.241C295.989 184.25 295.954 184.244 295.924 184.226C295.895 184.208 295.873 184.18 295.865 184.146C295.857 184.113 295.862 184.077 295.88 184.048C295.898 184.018 295.927 183.997 295.96 183.989L295.966 183.988Z"
        fill="#0C0C0C"
      />
      <path
        d="M276.012 236.841C274.585 236.218 273.517 235.552 271.08 233.716C269.321 232.396 267.686 230.917 266.196 229.3L278.712 205.484L279.256 204.449L290.545 210.384L280.753 229.019L280.708 229.102L280.793 229.433"
        fill="#EA394A"
      />
      <path
        d="M317.635 217.682C317.306 221.214 317.235 226.707 317.258 231.59C312.538 235.871 306.775 238.833 300.548 240.18C294.32 241.527 287.848 241.21 281.782 239.262C281.039 231.898 279.935 221.076 279.313 214.412C278.683 207.509 276.828 205.23 281.89 201.924C284.678 200.504 289.1 200.38 291.087 200.38C290.412 206.922 304.201 207.727 304.543 200.38C314.856 201.344 319.631 201.583 317.635 217.682Z"
        fill="#EA394A"
      />
      <path
        d="M291.711 190.489C292.851 191.069 294.152 191.252 295.407 191.01C295.451 191.002 295.496 191.006 295.537 191.022C295.578 191.039 295.614 191.066 295.64 191.102C295.666 191.138 295.681 191.181 295.683 191.225C295.686 191.269 295.676 191.313 295.654 191.352C295.281 192.012 294.392 193.139 292.869 192.68C291.885 192.383 291.514 191.373 291.375 190.746C291.365 190.702 291.368 190.657 291.383 190.615C291.398 190.573 291.425 190.536 291.461 190.509C291.496 190.482 291.539 190.466 291.583 190.463C291.627 190.459 291.672 190.468 291.711 190.489Z"
        fill="white"
      />
      <path
        d="M291.641 185.42C291.946 185.42 292.192 185.173 292.192 184.869C292.192 184.565 291.946 184.319 291.641 184.319C291.337 184.319 291.091 184.565 291.091 184.869C291.091 185.173 291.337 185.42 291.641 185.42Z"
        fill="#0C0C0C"
      />
      <path
        d="M296.934 186.346C297.239 186.346 297.485 186.1 297.485 185.796C297.485 185.492 297.239 185.245 296.934 185.245C296.63 185.245 296.384 185.492 296.384 185.796C296.384 186.1 296.63 186.346 296.934 186.346Z"
        fill="#0C0C0C"
      />
      <path
        d="M280.533 226.299H280.544C280.566 226.295 280.587 226.284 280.6 226.265C280.614 226.247 280.62 226.225 280.616 226.202L279.643 216.394C279.642 216.382 279.638 216.372 279.632 216.362C279.627 216.352 279.619 216.344 279.61 216.337C279.602 216.33 279.591 216.325 279.58 216.323C279.569 216.32 279.558 216.319 279.547 216.321C279.536 216.323 279.525 216.326 279.516 216.332C279.506 216.338 279.498 216.345 279.491 216.354C279.484 216.363 279.479 216.373 279.476 216.384C279.474 216.395 279.473 216.406 279.474 216.417L280.448 226.226C280.451 226.246 280.461 226.265 280.477 226.278C280.493 226.292 280.513 226.299 280.533 226.299Z"
        fill="#0C0C0C"
      />
      <path d="M279.26 218.828H269.321V231.017H279.26V218.828Z" fill="white" />
      <path
        d="M275.188 224.923C275.189 224.118 275.428 223.331 275.875 222.662C276.322 221.993 276.958 221.471 277.702 221.164C278.445 220.856 279.264 220.775 280.053 220.932C280.843 221.09 281.568 221.477 282.137 222.047C282.706 222.616 283.094 223.341 283.25 224.131C283.407 224.92 283.327 225.738 283.019 226.482C282.71 227.226 282.189 227.861 281.519 228.308C280.85 228.756 280.063 228.994 279.258 228.994C278.179 228.993 277.144 228.564 276.381 227.8C275.618 227.037 275.189 226.002 275.188 224.923ZM276.855 224.923C276.855 225.398 276.996 225.863 277.26 226.258C277.524 226.653 277.9 226.961 278.339 227.143C278.778 227.325 279.261 227.373 279.727 227.28C280.193 227.187 280.621 226.958 280.957 226.622C281.293 226.286 281.522 225.858 281.615 225.392C281.708 224.926 281.66 224.443 281.478 224.004C281.296 223.564 280.988 223.189 280.593 222.925C280.198 222.661 279.733 222.52 279.258 222.52C278.621 222.521 278.01 222.774 277.56 223.225C277.109 223.675 276.856 224.286 276.855 224.923Z"
        fill="white"
      />
      <path
        d="M279.182 226.431C279.215 226.225 279.32 226.037 279.478 225.901C279.636 225.764 279.837 225.687 280.046 225.684C280.041 225.676 280.036 225.666 280.034 225.657L279.738 225.592C279.532 225.552 279.346 225.441 279.212 225.279C279.078 225.116 279.004 224.913 279.003 224.703C279.005 224.571 279.036 224.441 279.094 224.323C279.152 224.204 279.236 224.1 279.339 224.018C279.443 223.936 279.563 223.878 279.691 223.848C279.82 223.818 279.953 223.817 280.082 223.845L280.78 223.995C280.78 223.993 280.78 223.99 280.78 223.988C280.782 223.982 280.784 223.977 280.786 223.971C280.605 223.886 280.457 223.741 280.368 223.561C280.279 223.381 280.253 223.176 280.295 222.979C280.372 222.463 280.991 222.293 281.445 222.391L283.253 222.715L282.643 222.31C282.451 222.177 282.316 221.975 282.266 221.746C282.216 221.517 282.255 221.278 282.375 221.077C282.441 220.975 282.526 220.888 282.626 220.82C282.727 220.753 282.84 220.706 282.959 220.684C283.078 220.661 283.2 220.663 283.318 220.69C283.436 220.716 283.547 220.766 283.645 220.837L290.935 229.179L289.316 228.444C288.814 229.183 288.102 229.755 287.273 230.087C286.443 230.419 285.534 230.495 284.66 230.306L280.707 229.445C280.501 229.404 280.315 229.293 280.181 229.131C280.047 228.969 279.973 228.766 279.972 228.555C279.975 228.366 280.038 228.182 280.153 228.03C280.268 227.879 280.428 227.769 280.61 227.715C280.604 227.702 280.602 227.687 280.604 227.673C280.606 227.659 280.611 227.646 280.62 227.635C280.628 227.624 280.639 227.616 280.652 227.61L279.922 227.453C279.692 227.408 279.488 227.278 279.351 227.088C279.213 226.898 279.153 226.664 279.182 226.431Z"
        fill="#E98862"
      />
      <path
        opacity="0.2"
        d="M282.101 224.289C282.12 224.285 282.137 224.274 282.149 224.259C282.161 224.244 282.167 224.225 282.167 224.206C282.166 224.186 282.159 224.167 282.147 224.153C282.134 224.138 282.117 224.128 282.097 224.125L280.876 223.918C280.865 223.916 280.854 223.916 280.843 223.918C280.832 223.92 280.821 223.924 280.812 223.931C280.803 223.937 280.795 223.945 280.789 223.955C280.783 223.964 280.778 223.975 280.777 223.986C280.775 223.997 280.775 224.008 280.778 224.019C280.78 224.03 280.785 224.041 280.792 224.05C280.799 224.059 280.807 224.066 280.817 224.072C280.826 224.078 280.837 224.082 280.848 224.083L282.069 224.29C282.08 224.292 282.09 224.291 282.101 224.289Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.2"
        d="M281.806 226.016C281.825 226.012 281.842 226.002 281.854 225.986C281.866 225.971 281.873 225.952 281.873 225.933C281.872 225.913 281.865 225.895 281.853 225.88C281.84 225.865 281.823 225.855 281.804 225.852L280.131 225.555C280.12 225.553 280.108 225.552 280.097 225.554C280.086 225.556 280.075 225.561 280.066 225.567C280.056 225.573 280.048 225.581 280.042 225.591C280.036 225.6 280.032 225.611 280.03 225.622C280.028 225.633 280.028 225.645 280.031 225.656C280.033 225.667 280.038 225.677 280.045 225.686C280.051 225.696 280.06 225.703 280.07 225.709C280.08 225.715 280.09 225.718 280.102 225.72L281.774 226.017C281.785 226.019 281.796 226.018 281.806 226.016Z"
        fill="#0C0C0C"
      />
      <path
        opacity="0.2"
        d="M281.498 227.889C281.517 227.885 281.534 227.874 281.547 227.859C281.559 227.843 281.565 227.824 281.564 227.805C281.564 227.785 281.556 227.766 281.543 227.752C281.531 227.737 281.513 227.727 281.493 227.724L280.7 227.603C280.679 227.601 280.658 227.607 280.641 227.62C280.624 227.633 280.613 227.652 280.609 227.674C280.606 227.695 280.611 227.716 280.623 227.734C280.635 227.751 280.654 227.764 280.675 227.768L281.468 227.89C281.478 227.891 281.488 227.891 281.498 227.889Z"
        fill="#0C0C0C"
      />
      <path
        d="M322.7 225.4C318.228 231.831 311.801 236.649 304.374 239.137L304.371 239.135C302.721 238.812 286.984 232.598 284.902 232.19L287.18 221.564C293.029 222.728 303.696 227.214 309.577 228.383L305.428 209.568C305.277 208.737 305.294 207.884 305.475 207.059C305.657 206.234 306.001 205.454 306.486 204.763C306.972 204.072 307.59 203.484 308.304 203.034C309.019 202.584 309.815 202.279 310.648 202.139C311.481 201.999 312.334 202.026 313.156 202.218C313.979 202.41 314.755 202.763 315.44 203.257C316.125 203.751 316.705 204.376 317.146 205.096C317.588 205.816 317.882 206.617 318.012 207.451L322.7 225.4Z"
        fill="#EA394A"
      />
      <path
        d="M314.038 229.768C314.059 229.767 314.079 229.759 314.095 229.744C314.11 229.73 314.119 229.71 314.121 229.689C314.123 229.668 314.116 229.647 314.103 229.63C314.09 229.613 314.071 229.602 314.05 229.598L309.628 228.098C309.617 228.096 309.606 228.097 309.595 228.099C309.584 228.102 309.574 228.107 309.564 228.113C309.555 228.12 309.548 228.128 309.542 228.138C309.536 228.148 309.533 228.158 309.531 228.17C309.528 228.192 309.534 228.215 309.547 228.233C309.56 228.251 309.58 228.263 309.603 228.266L314.025 229.766C314.029 229.767 314.033 229.768 314.038 229.768Z"
        fill="#0C0C0C"
      />
      <path
        d="M309.693 228.17C309.695 228.197 309.687 228.223 309.67 228.244C309.66 228.253 309.649 228.26 309.636 228.264C309.623 228.268 309.61 228.269 309.596 228.267L287.321 221.692L285.088 232.15L304.27 239.168C304.211 239.188 304.148 239.208 304.089 239.228C304.046 239.242 304.004 239.256 303.959 239.268L284.964 232.293C284.942 232.288 284.924 232.274 284.912 232.255C284.901 232.236 284.897 232.213 284.901 232.191L287.179 221.565C287.182 221.554 287.187 221.544 287.194 221.535C287.2 221.527 287.209 221.519 287.219 221.514C287.228 221.508 287.239 221.503 287.25 221.501C287.261 221.499 287.273 221.5 287.284 221.503L309.506 228.086L306.99 217.714C306.987 217.691 306.993 217.669 307.006 217.651C307.019 217.633 307.039 217.621 307.061 217.617C307.072 217.615 307.084 217.615 307.094 217.618C307.105 217.62 307.116 217.624 307.125 217.631C307.134 217.637 307.142 217.645 307.148 217.654C307.154 217.664 307.159 217.674 307.161 217.685L309.693 228.17Z"
        fill="#0C0C0C"
      />
      <path
        d="M19.2182 214.616C19.1975 214.616 19.177 214.612 19.1578 214.604C19.139 214.596 19.1219 214.584 19.1075 214.57C19.0931 214.555 19.0817 214.538 19.074 214.519C19.0663 214.5 19.0624 214.48 19.0625 214.459C19.0626 214.439 19.0668 214.419 19.0747 214.4L25.1735 199.904C25.1896 199.865 25.2201 199.835 25.2584 199.82C25.2966 199.804 25.3395 199.805 25.3776 199.821C25.4156 199.837 25.4457 199.867 25.4613 199.905C25.4769 199.944 25.4766 199.987 25.4605 200.025L19.3617 214.52C19.3498 214.549 19.3299 214.573 19.3044 214.59C19.2789 214.607 19.2489 214.616 19.2182 214.616Z"
        fill="#0C0C0C"
      />
      <path
        d="M55.1079 214.781H55.1011C55.0807 214.782 55.0603 214.778 55.041 214.771C55.0218 214.764 55.0042 214.754 54.9891 214.74C54.974 214.726 54.9618 214.71 54.9531 214.691C54.9444 214.673 54.9395 214.653 54.9386 214.632L54.2588 199.806C54.2583 199.765 54.2733 199.725 54.3008 199.695C54.3283 199.665 54.3662 199.646 54.407 199.643C54.4274 199.642 54.4478 199.645 54.4671 199.652C54.4863 199.659 54.504 199.669 54.5192 199.683C54.5343 199.697 54.5465 199.714 54.5552 199.732C54.5639 199.751 54.5688 199.771 54.5697 199.791L55.2495 214.618C55.2516 214.658 55.2379 214.697 55.2115 214.728C55.1851 214.758 55.148 214.777 55.1079 214.781Z"
        fill="#0C0C0C"
      />
      <path
        d="M480.473 152.175C480.223 158.489 478.449 164.649 475.301 170.128C472.153 175.607 467.725 180.242 462.396 183.638C457.067 187.034 450.995 189.089 444.699 189.627C438.403 190.166 432.07 189.172 426.242 186.731C423.508 185.586 420.915 184.129 418.515 182.39C413.927 179.078 410.108 174.814 407.32 169.89V169.885C404.347 164.618 402.636 158.734 402.322 152.694C402.007 146.654 403.097 140.624 405.506 135.076C407.915 129.529 411.578 124.616 416.206 120.723C420.835 116.83 426.303 114.063 432.181 112.64C438.059 111.217 444.187 111.176 450.084 112.521C455.98 113.866 461.484 116.559 466.164 120.391C470.844 124.222 474.572 129.086 477.054 134.601C479.537 140.116 480.707 146.131 480.473 152.175L480.473 152.175Z"
        fill="white"
      />
      <path
        opacity="0.8"
        d="M480.473 152.175C480.223 158.489 478.449 164.649 475.301 170.128C472.153 175.607 467.725 180.242 462.396 183.638C457.067 187.034 450.995 189.089 444.699 189.627C438.403 190.166 432.07 189.172 426.242 186.731C423.508 185.586 420.915 184.129 418.515 182.39C413.927 179.078 410.108 174.814 407.32 169.89V169.885C404.347 164.618 402.636 158.734 402.322 152.694C402.007 146.654 403.097 140.624 405.506 135.076C407.915 129.529 411.578 124.616 416.206 120.723C420.835 116.83 426.303 114.063 432.181 112.64C438.059 111.217 444.187 111.176 450.084 112.521C455.98 113.866 461.484 116.559 466.164 120.391C470.844 124.222 474.572 129.086 477.054 134.601C479.537 140.116 480.707 146.131 480.473 152.175L480.473 152.175Z"
        fill="black"
      />
      <path
        d="M440.995 130.009C444.959 130.009 448.127 131.097 450.498 133.274C452.908 135.451 454.113 138.424 454.113 142.195C454.113 146.121 452.87 149.075 450.382 151.057C447.894 153.04 444.59 154.031 440.47 154.031L440.237 158.637H434.464L434.173 149.483H436.097C439.867 149.483 442.744 148.978 444.726 147.967C446.747 146.957 447.758 145.032 447.758 142.195C447.758 140.135 447.156 138.522 445.951 137.356C444.784 136.189 443.152 135.606 441.053 135.606C438.954 135.606 437.302 136.17 436.097 137.297C434.892 138.424 434.289 139.999 434.289 142.02H428.051C428.051 139.688 428.576 137.608 429.625 135.781C430.675 133.954 432.171 132.536 434.115 131.525C436.097 130.514 438.39 130.009 440.995 130.009ZM437.263 171.523C436.058 171.523 435.047 171.114 434.231 170.298C433.415 169.482 433.007 168.471 433.007 167.266C433.007 166.061 433.415 165.051 434.231 164.234C435.047 163.418 436.058 163.01 437.263 163.01C438.429 163.01 439.42 163.418 440.237 164.234C441.053 165.051 441.461 166.061 441.461 167.266C441.461 168.471 441.053 169.482 440.237 170.298C439.42 171.114 438.429 171.523 437.263 171.523Z"
        fill="white"
      />
    </svg>
  );
};

export default NoOrgSvg;

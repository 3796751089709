import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";

const JoinRequestModal = ({ closeModal }) => {
  return (
    <Card
      elevation={0}
      sx={{
        height: "200px",
        width: "500px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 600 }}>
          Join Request
        </Typography>
        <Box sx={{ border: "2px solid black", p: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Organization Name
            </Typography>
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Typography sx={{ fontSize: "10px" }}>Added by</Typography>{" "}
              <Typography sx={{ fontWeight: 500 }}>BADKJ61</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>User - 15</Typography>
            <Typography>Apr 23,2023</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <FilledButton
              text="Accept"
              submit={() => console.log("submit")}
              bgColor="black"
              color="white"
            />
            <OutlinedButton text="reject" submit={closeModal} color="black" />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default JoinRequestModal;

import React, { useState } from "react";
import { fpost, fpatch } from "../../../API/callsApi";
import useAuth from "../../../hooks/useAuth";
import useStyles from "./ManageRolesModal.styles";
import { toast } from "react-hot-toast";
// @MUI
import {
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  Divider,
  Select,
  MenuItem,
  Card,
  Typography,
  Box,
} from "@material-ui/core";

const ManageRolesModal = ({ handleManageRolesModal, services, user }) => {
  const classes = useStyles();
  const [selectedService, setSelectedService] = useState("");
  const [userRole, setUserRole] = useState("");
  const { currentOrganization } = useAuth();

  const handleChangeRoleAndServices = async () => {
    if (selectedService) {
      const formData = {
        service: selectedService,
        user: user.id,
      };
      try {
        let obj = {
          url: `/connection/new/`,
          data: formData,
        };
        const res = await fpost(obj);
        if (res?.status === 200 || res?.status === 201) {
          toast.success("New Connection to service made");
        }
      } catch (error) {
        console.log(error);
        toast.error("Error on making new connection");
      }
    }
    if (userRole === "admin") {
      const url = `/organization/${currentOrganization}/register-admin/user/${user.id}/`;
      try {
        const res = await fpatch({ url });
        if (res.status === 200 || res.status === 201) {
          toast.success("Made User an admin");
        }
      } catch (error) {
        console.log("error", error);
        toast.error("Error occured, user couldn't be added as admin");
      }
    }
    handleManageRolesModal();
  };

  return (
    <Card className={classes.card}>
      <Typography className={classes.cardHeader}>Change Roles</Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          fontSize: "18px",
        }}
      >
        <p>
          {user?.first_name} {user?.last_name}
        </p>
        <p style={{ color: "rgba(0,0,0,0.6)" }}>{user?.email}</p>
      </div>
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="artist"
          name="radio-buttons-group"
          onChange={(event) => {
            setUserRole(event.target.value);
          }}
        >
          <Box className={classes.radioSelect}>
            <Typography style={{ fontFamily: "Poppins" }}>User</Typography>
            <FormControlLabel
              value="artist"
              control={
                <Radio style={{ color: "#000", marginRight: "-10px" }} />
              }
            />
          </Box>
          <Box className={classes.radioSelect}>
            <Typography style={{ fontFamily: "Poppins" }}>Admin</Typography>
            <FormControlLabel
              value="admin"
              control={
                <Radio style={{ color: "#000", marginRight: "-10px" }} />
              }
            />
          </Box>
        </RadioGroup>
        <Divider
          style={{
            backgroundColor: "#000",
            width: "530px",
            marginBottom: "30px",
          }}
        />
        <p className={classes.secondHeader}>Select Services</p>
        <div style={{ width: "100%" }}>
          <Select
            value={selectedService}
            labelId="filter-by-service"
            onChange={(e) => setSelectedService(e.target.value)}
            style={{
              border: "1px solid #000",
              padding: "5px",
              paddingLeft: "10px",
              height: "44px",
              marginTop: "0px",
            }}
            fullWidth={true}
          >
            {services.map((service, index) => {
              return (
                <MenuItem value={service.id} key={index}>
                  {service.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
      </FormControl>
      <div style={{ padding: "30px 131px", marginTop: "30px" }}>
        <Button
          className={classes.actionBtn}
          onClick={handleChangeRoleAndServices}
        >
          Make Changes
        </Button>
        <Button className={classes.cancelBtn} onClick={handleManageRolesModal}>
          Cancel
        </Button>
      </div>
    </Card>
  );
};

export default ManageRolesModal;

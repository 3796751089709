import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { fpost } from "../../../API/callsApi";
import useAuth from "../../../hooks/useAuth";
import useStyles from "./ManageRolesModal.styles";
import { toast } from "react-hot-toast";
// @MUI
import { Button, TextField, Card, Box, Typography } from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { ArrowLeft } from "phosphor-react";
//components

const NewUserOrganisationModal = ({
  setIsNewUserModalOpen,
  setTableView,
  services,
  getInvitedUser,
  tableView,
  existingUserData,
  isUserExist,
}) => {
  const classes = useStyles();
  const [selectedServices, setSelectedServices] = useState([]);
  const { currentOrganization } = useAuth();

  const formik = useFormik({
    initialValues: {
      userEmail: "",
      firstName: "",
      lastName: "",
    },

    onSubmit: async (values) => {
      const serviceObj = Object.fromEntries(
        selectedServices.map(({ id, name }) => [String(id), name])
      );
      let formData = {
        email: values.userEmail,
        services: serviceObj,
        first_name: values.firstName,
        last_name: values.lastName,
      };
      let formDataTwo = {
        email: values.userEmail,
        services: serviceObj,
      };
      try {
        let obj = {
          url: `/organization/${currentOrganization}/invite-user/`,
          data: isUserExist ? formDataTwo : formData,
        };
        const res = await fpost(obj);
        if (res?.status === 200) {
          if (tableView == "Invitations") {
            getInvitedUser();
          } else {
            setTableView("Invitations");
          }
          toast.success("Invitation sent to user");
          setIsNewUserModalOpen(false);
        }
      } catch (error) {
        toast.error("Error on Sending Invitation");
      }
    },
  });

  useEffect(() => {
    if (existingUserData) {
      formik.setFieldValue("userEmail", existingUserData?.email);
    }
  }, [existingUserData]);

  return (
    <Card className={classes.cardTwo}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Button style={{ minWidth: "0px", padding: "0px" }}>
          <ArrowLeft size={22} style={{ fontWeight: 800 }} />
        </Button>
        <Typography className={classes.header}>
          {isUserExist ? "User Found" : "Add New User"}
        </Typography>
        <div />
      </div>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
        }}
      >
        <>
          {isUserExist ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography style={{ textAlign: "center", fontSize: "18px" }}>
                A user with the following details already exists in the
                database. Would you like to go ahead with this user?
              </Typography>
              <Typography>
                Email:{" "}
                <span
                  style={{ fontWeight: 500, color: "black", fontSize: "18px" }}
                >
                  {existingUserData?.email || ""}
                </span>
              </Typography>
              <Typography>
                Name:{" "}
                <span
                  style={{ fontWeight: 500, color: "black", fontSize: "18px" }}
                >
                  {existingUserData?.first_name || ""}{" "}
                  {existingUserData?.last_name || ""}
                </span>
              </Typography>
            </div>
          ) : (
            <>
              <TextField
                placeholder="User Mail Id"
                fullWidth
                type="email"
                name="userEmail"
                id="userEmail"
                InputProps={{ disableUnderline: true }}
                value={formik.values.userEmail}
                onChange={formik.handleChange}
                style={{
                  border: "2px solid #000",
                  padding: "5px",
                  boxSizing: "border-box",
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  width: "100%",
                }}
              >
                <TextField
                  placeholder="First Name"
                  fullWidth
                  type="text"
                  name="firstName"
                  id="firstName"
                  InputProps={{ disableUnderline: true }}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  style={{
                    border: "2px solid #000",
                    padding: "5px",
                    boxSizing: "border-box",
                  }}
                />
                <TextField
                  placeholder="Last Name"
                  fullWidth
                  type="text"
                  name="lastName"
                  id="lastName"
                  InputProps={{ disableUnderline: true }}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  style={{
                    border: "2px solid #000",
                    padding: "5px",
                    boxSizing: "border-box",
                  }}
                />
              </div>
            </>
          )}
        </>
        <Autocomplete
          fullWidth
          style={{
            border: "2px solid #000",
            padding: "10px 5px 0px 5px",
            boxSizing: "border-box",
          }}
          multiple
          id="select-services"
          options={services}
          getOptionLabel={(option) => option.name}
          onChange={(e, v) => setSelectedServices(v)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              placeholder="Select A Service"
            />
          )}
        />
        <Box className={classes.divContent}>
          <p>Role</p>
          <p>User</p>
        </Box>
        <div>
          <Button className={classes.actionBtn} type="submit">
            {isUserExist ? "Invite User" : "Add User"}
          </Button>
          <Button
            className={classes.cancelBtn}
            onClick={() => setIsNewUserModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default NewUserOrganisationModal;

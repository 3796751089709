import React, { createContext, useState } from "react";

export const GlobalState = createContext();

const Context = ({ children }) => {
  const [ssoadmin, setSsoadmin] = useState(false);
  const [orgadmin, setOrgadmin] = useState(false);
  const [user, setUser] = useState(false);

  return (
    <GlobalState.Provider
      value={{ ssoadmin, setSsoadmin, orgadmin, setOrgadmin, user, setUser }}
    >
      {children}
    </GlobalState.Provider>
  );
};
export default Context;

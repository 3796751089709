import * as React from "react";
//@MUI
import {
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
//icons
import SearchIcon from "@mui/icons-material/Search";
//component import

const OrganisationsTools = ({
  tableView,
  services,
  setSelectedRole,
  selectedRole,
  selectedService,
  setSelectedService,
  onChangeSearchQuery,
}) => {
  const roles = [
    { name: "User", id: 1 },
    { name: "Admin", id: 2 },
  ];

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginLeft: "23%",
        marginBottom: "20px",
        height: "44px",
        fontFamily: "Poppins",
      }}
    >
      <span style={{ fontSize: "24px", fontWeight: "600" }}>Manage Users</span>
      <div style={{ display: "flex", gap: "20px" }}>
        {tableView == "Active Users" && (
          <>
            <div
              style={{
                width: "280px",
                height: "24px",
                border: "1px solid #000",
                padding: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                disableUnderline="false"
                placeholder="Search"
                onChange={(e) => onChangeSearchQuery(e)}
                style={{ width: "90%", fontFamily: "Poppins" }}
              />
              <SearchIcon />
            </div>
            <div>
              <FormControl fullWidth={true}>
                <InputLabel
                  id="filter-by-service"
                  style={{ marginLeft: "5px", marginTop: "-8px" }}
                >
                  {selectedService !== "" ? "" : "Services"}
                </InputLabel>
                <Select
                  value={selectedService}
                  labelId="filter-by-service"
                  onChange={(e) => setSelectedService(e.target.value)}
                  style={{
                    border: "1px solid #000",
                    width: "160px",
                    padding: "5px",
                    paddingLeft: "10px",
                    height: "44px",
                    marginTop: "0px",
                    fontFamily: "Poppins",
                  }}
                >
                  {services.map((service, index) => {
                    return (
                      <MenuItem
                        value={service.id}
                        key={index}
                        style={{ fontFamily: "Poppins" }}
                      >
                        {service.name}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>

            <div>
              <FormControl fullWidth={true}>
                <InputLabel
                  id="filter-by-role"
                  style={{ marginLeft: "5px", marginTop: "-8px" }}
                >
                  {selectedRole !== "" ? "" : "Roles"}
                </InputLabel>
                <Select
                  value={selectedRole}
                  labelId="filter-by-role"
                  onChange={(e) => setSelectedRole(e.target.value)}
                  style={{
                    width: "160px",
                    height: "44px",
                    border: "1px solid #000",
                    padding: "5px",
                    marginTop: "0px",
                    fontFamily: "Poppins",
                  }}
                >
                  {roles.map((role) => {
                    return (
                      <MenuItem
                        value={role.name}
                        key={role.id}
                        style={{ fontFamily: "Poppins" }}
                      >
                        {role.name}
                      </MenuItem>
                    );
                  })}
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrganisationsTools;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    padding: "7px",
    margin: "9px 0",
    width: "18rem",
    border: "2px solid black",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  passwordInput: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    padding: "7px",
    border: "none",
    outline: "none",
  },
  button: {
    padding: "1px",
    margin: "9px 0",
    width: "18rem",
    backgroundColor: "#000000",
    color: "white",
    border: "none",
    height: "44px",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    cursor: "pointer",
  },

  logo: {
    position: "absolute",
    margin: "136px 0px 0 121px",
  },

  loginText: {
    position: "absolute",
    width: "95px",
    height: "48px",
    left: "121px",
    top: "200px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "32px",
    lineHeight: "48px",
    color: "#000000",
  },

  form: {
    display: "flex",
  },
});

export default useStyles;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    padding: "7px",
    margin: "9px 0",
    width: "16.4rem",
    border: "2px solid black",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },

  button: {
    padding: "6px",
    margin: "9px 0",
    width: "9em",
    marginRight: "1rem",
    backgroundColor: "#000000",
    color: "white",
    border: "none",
    height: "44px",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    cursor: "pointer",
  },
  buttonD: {
    padding: "1px",
    margin: "9px 0",
    width: "5rem",
    color: "black",
    backgroundColor: "#fff",
    border: "2px solid black",
    height: "44px",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    cursor: "pointer",
  },

  btn: {
    margin: "0 2.5rem 1rem",
    width: "8rem",
  },

  setwidth: {
    width: "13rem",
  },

  imageBlock: {
    position: "relative",
    height: "172px",
    width: "172px",
  },

  editIcon: {
    left: "7.5rem",
    top: "7.5rem",
    position: "absolute",
    cursor: "pointer",
  },

  heading: {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Poppins, sans-serif",
  },

  text: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
});

export default useStyles;

import axios from "axios";
import { createCookie, deleteCookie } from "../authorization";

const BASE_URL = process.env.REACT_APP_BASE_URL;
//request interceptor to add the auth token header to requests
axios.interceptors.request.use(
  (config) => {
    let accessToken = readCookie("access");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    let refreshToken = readCookie("refresh");

    if (
      refreshToken &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      // if (Date.now() >= getPayload(readCookie("refresh")).exp * 1000) {
      //   deleteCookie("access");
      //   deleteCookie("refresh");
      //   window.location.href = "/";
      // }
      if (error?.response?.data?.messages) {
        if (
          error?.response?.data?.code === "token_not_valid" &&
          error?.response?.data?.messages[0]?.token_type !== "access"
        ) {
          deleteCookie("access");
          deleteCookie("refresh");
          window.location.href = "/";
        } else {
          originalRequest._retry = true;
          try {
            const res = await axios.post(`${BASE_URL}/api/token/refresh/`, {
              refresh: refreshToken,
            });
            if (res.status === 200 && res.data?.code !== "token_invalid") {
              createCookie("access", res.data.access);
              return axios(originalRequest);
            } else {
              deleteCookie("access");
              deleteCookie("refresh");
              window.location.href = "/";
            }
          } catch (error) {
            deleteCookie("access");
            deleteCookie("refresh");
            window.location.href = "/";
          }
        }
      } else {
        deleteCookie("access");
        deleteCookie("refresh");
        window.location.href = "/";
      }
    }
    return Promise.reject(error);
  }
);

export function readCookie(name) {
  let key = name + "=";
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length);
    }
  }
  return false;
}

export const fpost = async ({ url, data }) => {
  const res = await axios.post(BASE_URL + url, data);

  return res;
};

export const fpatch = async ({ url, data }) => {
  const res = await axios.patch(BASE_URL + url, data);

  return res;
};

export const fpost2 = async ({ url, token_value, data }) => {
  let headers = {};
  // if (token_value) {
  const access = token_value;
  headers = { Authorization: `Bearer ${access}` };
  // console.log({ data, headers });
  // }
  const res = await axios.post(BASE_URL + url, data, {
    headers,
  });
  return res;
};

export const fget = async ({ url, token_value }) => {
  let headers = {};
  const access = token_value ? token_value : readCookie("accessToken");
  headers = { Authorization: `Bearer ${access}` };
  // console.log({ data, headers });
  // console.log("get done");
  const res = await axios.get(BASE_URL + url, {
    headers,
  });
  return res;
};

export const fdelete = async ({ url, token_value, data }) => {
  let headers = {};
  const access = token_value;
  headers = { Authorization: `Bearer ${access}` };
  // console.log({ data, headers });
  const res = await axios.delete(BASE_URL + url, data, {
    headers,
  });
  return res;
};

export const fget2 = async ({ url, data }) => {
  const res = await axios.get(BASE_URL + url, data);

  return res;
};

import React from "react";
import { Toaster } from "react-hot-toast";

const HotToast = () => {
  return (
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      gutter={8}
      toastOptions={{
        className: "",
        duration: 1500,

        // options for specific types
        success: {
          style: {
            backgroundColor: "green",
            color: "white",
          },
        },
        error: {
          style: {
            backgroundColor: "red",
            color: "white",
          },
        },
      }}
    />
  );
};

export default HotToast;

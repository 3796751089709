export function readCookie(name) {
  let key = name + "=";
  let cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    if (cookie.indexOf(key) === 0) {
      return cookie.substring(key.length, cookie.length);
    }
  }
  return null;
}

export function deleteCookies() {
  var allCookies = document.cookie.split(";");
  // The "expire" attribute of every cookie is
  // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
  for (var i = 0; i < allCookies.length; i++)
    document.cookie = allCookies[i] + "=;expires=" + new Date(0).toUTCString();
}

export function deleteCookie(name) {
  var now = new Date();
  now.setMonth(now.getMonth() - 10);
  let expiration = now.toUTCString();
  createCookie(name, "", expiration);
}

export function createCookie(key, value, time) {
  let expiration;
  if (!time) {
    var now = new Date();
    key.includes("access")
      ? now.setMinutes(now.getMinutes() + 5)
      : now.setHours(now.getHours() + 168);
    expiration = now.toUTCString();
  } else {
    // console.log("time was passed so setting expiraiton time");
    expiration = time;
  }
  //vercel generates dynamic url for each commit, if the deployment is vercel then set the domain from enviroment
  const domain = process.env.REACT_APP_DOMAIN === "vercel" ? process.env.REACT_APP_VERCEL_URL : process.env.REACT_APP_DOMAIN;
  
  let cookie =
    escape(key) +
    "=" +
    escape(value) +
    ";expires=" +
    expiration +
    ";" +
    "path=/;" +
    "domain=" +
    domain;
  // console.log("now setting up the cookie");
  document.cookie = cookie;
}

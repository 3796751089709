import React from "react";
import NoOrgSvg from "../../ReusableComponents/Images/NoOrgSvg";
import { Grid, Typography } from "@mui/material";

const NoOrganization = () => {
  return (
    <Grid container>
      <Grid
        item
        md={12}
        lg={6}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <NoOrgSvg />
      </Grid>
      <Grid
        item
        md={12}
        lg={6}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <Typography
          variant="h4"
          sx={{ fontWeight: 600, fontSize: "48px", textAlign: "center" }}
        >
          You are not part of Organization yet!
        </Typography>
        <Typography sx={{ fontSize: "24px", textAlign: "center" }}>
          Please contact your organization admin to add you to the organization.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoOrganization;

import * as React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const PasswordInput = ({
  password,
  toggleShowPassword,
  showPassword,
  input,
}) => {
  return (
    <div
      style={{
        margin: "9px 0",
        width: "286px",
        border: "2px solid black",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {input}
      {password && (
        <button
          type="button"
          style={{ backgroundColor: "transparent", border: "none" }}
          onClick={toggleShowPassword}
        >
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </button>
      )}
    </div>
  );
};

export default PasswordInput;

import React, { createContext, useEffect, useState } from "react";
// import userInfoProviderHook from "../hooks/useLogin";
import { fget } from "../API/callsApi";

export const AuthContext = createContext(null);

const UserDataProvider = ({ children }) => {
  const allContext = userInfoProviderHook();
  return (
    <AuthContext.Provider value={allContext}>{children}</AuthContext.Provider>
  );
};

export default UserDataProvider;

const userInfoProviderHook = () => {
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [userData, setUserData] = useState();
  const [organizationsList, setOrganizationsList] = useState([]);
  const [currentOrganization, setCurrentOrganization] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentGreeting, setCurrentGreeting] = useState();

  useEffect(() => {
    getUserData();
    getGreeting();
  }, []);

  useEffect(() => {
    if (userData) {
      getOrgList();
    }
  }, [userData]);

  useEffect(() => {
    const isAvailable = userData?.admin_orgs?.some(
      (sinOrg) => sinOrg?.id === Number(currentOrganization)
    );
    setIsAdmin(isAvailable);
  }, [currentOrganization, userData]);

  //gets the greeting according to the time of the day
  const getGreeting = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    let greeting;
    if (hours < 12) {
      greeting = "Good Morning";
    } else if (hours < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    setCurrentGreeting(greeting);
  };

  // reload user information on reload
  const getUserData = async () => {
    let userData = {
      url: "/user/",
    };

    try {
      const res = await fget(userData);
      if (res?.status === 200) {
        setUserData(res.data);
        setIsUserDataLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // load user information on first time on log in
  const loadUserData = async (token) => {
    let userData = {
      url: "/user/",
      token_value: token,
    };

    try {
      const res = await fget(userData);
      if (res?.status === 200) {
        setUserData(res.data);
        setIsUserDataLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get organizations list of an user
  const getOrgList = async () => {
    let userData = {
      url: "/organizations/",
    };
    try {
      const res = await fget(userData);
      if (res?.status === 200 && res?.data?.length) {
        setOrganizationsList(res?.data);
        const storedId = localStorage.getItem("currentOrganization");
        const exist = res?.data?.some((singleOrg) => singleOrg.id === storedId);
        if (storedId && exist) {
          setCurrentOrganization(storedId);
        } else {
          if (res?.data?.length) {
            setCurrentOrganization(res?.data[0]?.id);
            localStorage.setItem("currentOrganization", res?.data[0]?.id);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // return all value/function which will needed in other component
  return {
    isAdmin, // will return a boolean value which will define user role based on org
    isUserDataLoading, // state of user data loading
    userData, // user whole information
    loadUserData, // function to load user data after first log in
    organizationsList, // organizations list to load in other
    currentOrganization, // current selected organization
    setCurrentOrganization, // set state to select new organization
    currentGreeting, //greeting to display according to current time
  };
};

import React, { useState } from "react";
// @MUI
import { Button, TextField, Chip } from "@material-ui/core";

const BulkUserOrganisationModal = ({ setIsBulkUserModalOpen }) => {
  const [emails, setEmails] = useState([]);

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    setEmails([...emails, email]);
    event.target.email.value = "";
  };

  return (
    <div
      style={{
        minHeight: "400px",
        width: "532px",
        background: "#fff",
        padding: "36px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
      }}
    >
      <p
        style={{
          fontSize: "24px",
          fontWeight: "600",
          fontFamily: "Poppins",
          marginBottom: "0px",
        }}
      >
        Add Bulk Users
      </p>
      {/* <TextField id="outlined-basic" label="User Mail Id" fullWidth style={{border: "2px solid #000"}}/> */}
      <div style={{ width: "100%", border: "1px solid #000" }}>
        <form onSubmit={handleEmailSubmit}>
          <TextField id="email" label="Email Address" type="email" fullWidth />
        </form>
        {emails.map((email, index) => (
          <Chip
            key={index}
            label={email}
            onDelete={() => {
              setEmails(emails.filter((e) => e !== email));
            }}
            style={{ margin: "5px" }}
          />
        ))}
      </div>
      {/* <DropDown
        value={"Service1"}
        menuItems={services}
        label={"Service"}
        fullWidth={true}
        style={style}
      /> */}
      <div>
        <Button
          style={{
            height: "44px",
            width: "160px",
            background: "#000",
            color: "#fff",
            marginRight: "12px",
            textTransform: "none",
            fontSize: "16px",
          }}
        >
          Add Bulk Users
        </Button>
        <Button
          style={{
            height: "44px",
            width: "97px",
            background: "#fff",
            border: "2px solid #000",
            textTransform: "none",
            fontSize: "16px",
          }}
          onClick={() => setIsBulkUserModalOpen(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default BulkUserOrganisationModal;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    height: "414px",
    width: "391px",
    backgroundColor: "#fff",
    padding: "12px 18px 24px 24px",
    fontWeight: "500",
    fontFamily: "Poppins",
  },
  contentOne: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "24px",
  },
  contentTwo: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "18px",
    height: "128px",
    marginTop: "10px",
    marginBottom: "36px",
  },
  contentTwoRight: {
    fontSize: "13px",
    boxSizing: "border-box",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  infoTypeOne: {
    marginTop: "0px",
    marginBottom: "5px",
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "13px",
  },
  infoTypeTwo: {
    marginTop: "0px",
    marginBottom: "0px",
    fontSize: "24px",
  },
  cardContentThree: {
    width: "100%",
    marginTop: "18px",
    marginBottom: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardContentfour: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "44px",
  },
});

export default useStyles;

import React, { useState } from "react";
import { fdelete } from "../../../API/callsApi";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-hot-toast";
//@MUI
import { Button, TableCell, TableRow, Modal } from "@material-ui/core";
import useStyles from "./OrganisationsTableActiveUsers.styles";
//icons
import Trash from "../../../assets/Trash.png";
//components
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";

const OrganisationsTableSentInvite = ({ userToDisplay, getInvitedUser }) => {
  const classes = useStyles();
  const { currentOrganization } = useAuth();
  const [iseDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModal = () => {
    setDeleteModalOpen(!iseDeleteModalOpen);
  };

  const deleteInvitation = async (e) => {
    e.preventDefault();
    const url = `/organization/${currentOrganization}/delete-invite/${userToDisplay.id}/`;
    try {
      const res = await fdelete({ url });
      if (res.status === 200) {
        getInvitedUser();
        handleDeleteModal();
        toast.success("Invitation Deleted");
      }
    } catch (error) {
      console.log(error);
      toast.error("Some error occured");
    }
  };

  return (
    <>
      <TableRow key={userToDisplay?.id}>
        <TableCell align="left" className={classes.tableCellThree}>
          {userToDisplay?.user_email
            ? userToDisplay.user_email
            : userToDisplay?.user?.email}
        </TableCell>
        <TableCell align="center" className={classes.tableCellfour}>
          User
        </TableCell>
        <TableCell align="center" className={classes.tableCellfour}>
          <p style={{ margin: "0px" }}>
            {userToDisplay?.sent_by?.first_name}{" "}
            {userToDisplay?.sent_by?.last_name}
          </p>
          {userToDisplay?.updated_date &&
            new Date(userToDisplay?.updated_date).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
        </TableCell>
        <TableCell align="right" className={classes.tableCellfour}>
          <Button
            style={{
              minWidth: "0px",
              minHeight: "0px",
              padding: "5px",
              visibility: "visible",
            }}
            onClick={handleDeleteModal}
          >
            <img src={Trash} alt="icons" />
          </Button>
        </TableCell>
      </TableRow>
      <div>
        <Modal
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          open={iseDeleteModalOpen}
          onClose={handleDeleteModal}
        >
          <DeleteModal
            handleDelete={deleteInvitation}
            closeModal={handleDeleteModal}
            text1={
              userToDisplay.user !== null
                ? `${userToDisplay?.user?.first_name} ${userToDisplay?.user?.last_name}`
                : ""
            }
            text2={
              userToDisplay.user !== null
                ? userToDisplay?.user?.email
                : userToDisplay.user_email
            }
            btnText={"Delete Invitation"}
          />
        </Modal>
      </div>
    </>
  );
};

export default OrganisationsTableSentInvite;

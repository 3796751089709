import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: "40px 157px",
    fontFamily: "Poppins",
    marginTop: "83px",
  },
  containerOne: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
  },
  addUserBtn: {
    height: "44px",
    background: "#000",
    color: "#fff",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Poppins",
    "&:hover": {
      background: "#000",
      color: "#fff",
    },
  },
  tableContainer: {
    width: "100%",
    border: "2px solid #000",
    maxHeight: "65vh",
    "&::-webkit-scrollbar": {
      width: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#252A38",
    },
  },
  tableHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "14px",
    color: "rgba(0, 0, 0, 0.6)",
    fontFamily: "Poppins",
    marginBottom: "5px",
    marginTop: "-7px",
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default useStyles;

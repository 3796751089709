import React from "react";
//@MUI
import {
  TableCell,
  TableRow,
  // ListItemSecondaryAction,
} from "@material-ui/core";
import useStyles from "./OrganisationsTableActiveUsers.styles";

const OrganisationsTableFailedInvites = ({ userToDisplay }) => {
  const classes = useStyles();

  return (
    <>
      <TableRow key={userToDisplay?.id}>
        <TableCell
          component="th"
          scope="row"
          align="left"
          className={classes.tableCellThree}
        >
          {userToDisplay?.user?.first_name} {userToDisplay?.user?.last_name}
        </TableCell>
        <TableCell align="center" className={classes.tableCellfour}>
          {userToDisplay?.sent_date &&
            new Date(userToDisplay?.sent_date).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
        </TableCell>
        <TableCell align="center" className={classes.tableCellfour}>
          {userToDisplay?.user?.email}
        </TableCell>
        <TableCell
          align="right"
          style={{ fontSize: "16px", color: "#F03333", width: "25%" }}
        >
          Cancelled on{" "}
          {userToDisplay?.updated_date &&
            new Date(userToDisplay?.updated_date).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrganisationsTableFailedInvites;

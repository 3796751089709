import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LaunchIcon from "@mui/icons-material/Launch";
import React, { useEffect, useState } from "react";
import { fget } from "../../../API/callsApi";
import useAuth from "../../../hooks/useAuth";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";
import { toast } from "react-hot-toast";
import { Info } from "phosphor-react";

const ManageServices = () => {
  const [status, setStatus] = useState("all");
  const { isAdmin, currentOrganization } = useAuth();
  const [services, setServices] = useState([]);
  const [isServicesLoaded, setIsServicesLoaded] = useState(true);

  useEffect(() => {
    if (currentOrganization) {
      getServices();
    }
  }, [currentOrganization, status]);

  const getServices = async () => {
    setIsServicesLoaded(false);
    const url =
      status === "all"
        ? `/service/?org=${currentOrganization}`
        : `/service/?org=${currentOrganization}&active=${
            status === "active" ? "True" : "False"
          }`;

    try {
      const res = await fget({ url });
      setServices(res?.data);
      setIsServicesLoaded(true);
    } catch (error) {
      console.log(error);
      setIsServicesLoaded(true);
    }
  };
  return (
    <Box>
      {isServicesLoaded ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Manage Services
            </Typography>
            <FormControl sx={{ width: "200px" }}>
              <InputLabel id="filter-by-status">Status</InputLabel>
              <Select
                labelId="filter-by-status"
                value={status}
                label="Status"
                sx={{ borderRadius: "0px" }}
                onChange={(e) => setStatus(e.target.value)}
                size="small"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="inActive">InActive</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="outlined"
              sx={{
                color: "black",
                display: "none",
                alignItems: "center",
                gap: "5px",
                border: "2px solid black",
                borderRadius: "0px",
                "&:hover": {
                  border: "2px solid gray",
                },
              }}
            >
              <Typography>Explore Services</Typography> <LaunchIcon />
            </Button>
          </Box>
          <Box sx={{ mt: 2 }}>
            {services?.length ? (
              <TableContainer sx={{ border: "2px solid black", width: "100%" }}>
                <Table sx={{ width: "100%", px: 2 }}>
                  <TableBody>
                    {services?.map((singleService) => (
                      <TableRow
                        key={singleService?.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" sx={{ fontWeight: 600 }}>
                          {singleService?.name}
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            <span style={{ fontSize: "10px" }}>
                              Subscribed on{"  "}
                            </span>
                            <span>
                              {singleService?.join_date &&
                                new Intl.DateTimeFormat("en-US", {
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                }).format(new Date(singleService?.join_date))}
                            </span>
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            {singleService?.active ? "Active" : "InActive"}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography>
                            User - {singleService?.user_count}
                          </Typography>
                        </TableCell>
                        {isAdmin ? (
                          <TableCell align="right">
                            <Button
                              onClick={() =>
                                toast.error("Functionality will be there")
                              }
                            >
                              <MoreHorizIcon />
                            </Button>
                          </TableCell>
                        ) : (
                          ""
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  fontSize: "24px",
                  fontWeight: 500,
                  height: "220px",
                }}
              >
                <Info size={50} />{" "}
                <span>
                  You weren’t subscribed to any services yet, please contact
                  your Team ADMIN
                </span>
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <CommonLoader color="black" height="250px" />
      )}
    </Box>
  );
};

export default ManageServices;

import React from "react";
import { Route } from "react-router-dom";
import Login from "./components/Login/Login";

const RouteRequiresLogin = (props) => {
  let userIsLogged = false;
  let data = localStorage.getItem("refresh");
  data ? (userIsLogged = true) : (userIsLogged = false);

  return <Route {...props}>{userIsLogged ? props.children : <Login />}</Route>;
};

export default RouteRequiresLogin;

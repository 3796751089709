import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Logo from "../../assets/logo.png";
import img from "../../assets/mainimage.png";
import { readCookie, fpatch } from "../../API/callsApi";
import useStyles from "./styles";
import * as yup from "yup";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import PasswordInput from "../CommonComponent/PasswordInput";

const Schema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
  password: yup
    .string()
    .required("Wrong Input!")
    .min(8, "Minimum eight characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "At least one letter, one number and one special character"
    ),
  confirmpassword: yup
    .string()
    .required("Passwords does not match")
    .test("passwords-match", "Passwords don't match", function (value) {
      return this.parent.password === value;
    }),
});

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const classes = useStyles();
  const history = useHistory();

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: "100vh",
      }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ ...snackValue, isOpen: false })}
        // message={`${snackValue.message}`}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <Grid
        container
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "45%",
        }}
      >
        <Grid>
          <div>
            <img src={Logo} alt="logo" width="151" height="40" />
          </div>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              pt: 3,
              pb: 2,
            }}
          >
            <span
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "32px",
                fontWeight: "600",
              }}
            >
              Set new Password?
            </span>
          </Typography>
          <span
            style={{
              fontFamily: "Poppins, sans-serif",
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            Dont worry enter your registered
            <br /> email below
          </span>

          <Formik
            initialValues={{
              email: "",
              password: "",
              confirmpassword: "",
            }}
            validationSchema={Schema}
            onSubmit={(values) => {
              setLoading(true);
              let formdata = new FormData();
              formdata.append("email", values.email);
              formdata.append(
                "reset_code",
                window?.location?.href
                  ?.split("/")[4]
                  ?.replace("?resetcode=", "")
              );
              formdata.append("new_password", values.confirmpassword);

              let obj = {
                url: "/reset-password-code/",
                token_value: readCookie("accessToken"),
                data: formdata,
              };

              fpatch(obj)
                .then((res) => {
                  if (res.status === 200 ||res.status === 201) {
                    setSnackValue({
                      isOpen: true,
                      message: "Password Updated Successfully",
                      isError: false,
                    });
                    setLoading(false);
                    history.push("/");
                  }
                })
                .catch(() => {
                  setLoading(false);

                  setSnackValue({
                    isOpen: true,
                    message: "Oops, something went wrong, try again",
                    isError: true,
                  });
                });
            }}
          >
            {({ values, errors, handleSubmit, handleChange, handleBlur }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <label htmlFor="email"></label>
                  <input
                    type="email"
                    className={classes.input}
                    name="email"
                    placeholder="Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.email}
                  />
                  <div style={{ color: "red" }}>{errors.email}</div>

                  <label htmlFor="passowrd"></label>

                  <PasswordInput
                    input={
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className={classes.passwordInput}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.password}
                      />
                    }
                    toggleShowPassword={toggleShowPassword}
                    showPassword={showPassword}
                    password={values.password}
                  />
                  <div style={{ color: "red" }}>{errors.password}</div>

                  <label htmlFor="confirmpassword"></label>
                  <input
                    type="password"
                    name="confirmpassword"
                    placeholder="Confirm Password"
                    className={classes.input}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmpassword}
                  />
                  <div style={{ color: "red" }}>{errors.confirmpassword}</div>
                  <button type="submit" className={classes.button}>
                    {!loading && <span>Set new password</span>}
                    {loading && (
                      <CircularProgress
                        width="20"
                        height="20"
                        style={{ color: "white" }}
                      />
                    )}
                  </button>
                </form>
              );
            }}
          </Formik>
        </Grid>
      </Grid>
      <Grid
        item
        xs={true}
        sx={{
          backgroundImage: `url(${img})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
    </Grid>
  );
};

export default ResetPassword;

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import BannerImageSvg from "../../ReusableComponents/Images/BannerImageSvg";

const HomePageBanner = () => {
  return (
    <Box
      sx={{
        height: "35vh",
        bgcolor: "black",
        py: 3,
        color: "white",
        px: 20,
      }}
    >
      <Grid container>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <BannerImageSvg />
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ fontSize: "44px", fontWeight: 600 }}>
            Filmmaking made Easy
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomePageBanner;

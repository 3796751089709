import { Box, Typography } from "@mui/material";
import React from "react";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";

const OrganizationDetails = ({
  isOrganizationDetailsLoaded,
  organizationDetails,
}) => {
  return (
    <Box
      sx={{
        height: "35vh",
        bgcolor: "black",
        py: 3,
      }}
    >
      {isOrganizationDetailsLoaded ? (
        <Box
          sx={{
            mx: 20,
            border: "2px solid white",
            color: "white",
            px: 6,
            py: 2,
            height: "85%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            gap: "30px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box>
              <Typography variant="h4" sx={{ fontWeight: 600 }}>
                {organizationDetails?.name}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 600, textAlign: "center" }}
              >
                {organizationDetails?.service_count ?? 0}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Total Services
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 600, textAlign: "center" }}
              >
                {organizationDetails?.active_services ?? 0}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Active Services
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ border: "1px solid #FFFFFF", p: 2 }}>
              <Typography>Owner Name</Typography>

              <Typography sx={{ mt: 1, textAlign: "center", fontSize: "12px" }}>
                Not found
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 600, textAlign: "center" }}
              >
                {Number(organizationDetails?.admin_count ?? 0) +
                  Number(organizationDetails?.user_count ?? 0)}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Total Users
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 600, textAlign: "center" }}
              >
                {organizationDetails?.admin_count ?? 0}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Admins
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{ fontWeight: 600, textAlign: "center" }}
              >
                {organizationDetails?.user_count ?? 0}
              </Typography>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Users
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <CommonLoader color="white" height="250px" />
      )}
    </Box>
  );
};

export default OrganizationDetails;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#000",
    paddingRight: "0",
    // paddingLeft: "5rem",
    paddingTop: "1rem",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  menuButton: {
    fontWeight: 700,
    size: "18px",
    marginLeft: "38px",
    textTransform: "capitalize",
    paddingLeft: "0px",
  },
  border: {
    border: "1px solid white",
    fontSize: "16px",
    marginLeft: "38px",
    textTransform: "capitalize",
    color: "white",
    fontFamily: "Poppins,sans-serif",
  },
  noborder: {
    fontSize: "16px",
    marginLeft: "38px",
    textTransform: "capitalize",
    color: "white",
    fontFamily: "Poppins, sans-serif",
    textDecoration: "none",
  },
  toolbar: {
    display: "flex",
    flexWrap: "wrap",
    // marginRight: "5rem",
    padding: "1rem 0",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    textUnderlineOffset: "3px",
    textDecoration: "underline",
  },
  drawerContainer: {
    padding: "20px 30px",
    color: "black",
    textDecoration: "none",
  },
  mobMenu: {
    color: "black",
    textDecoration: "none",
    fontFamily: "Poppins, sans-serif",
    paddingTop: "0.55rem",
  },
  orgDropdown: {
    padding: "8px 10px",
    fontSize: "16px",
    fontWeight: 500,
    backgroundColor: "black",
    color: "white",
    border: "2px solid white",
    width: "200px",
  },
  dropdownOption: {
    backgroundColor: "white",
    borderRadius: "0px",
    fontSize: "16px",
    fontWeight: 500,
  },
}));

export default useStyles;

import React, { useEffect, useState } from "react";
import Navbar from "../../Navbar/Navbar";
import HomePageBanner from "../../HomePage/HomePageBanner/HomePageBanner";
import {
  Box,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Modal,
} from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import JoinRequestModal from "../../HomePage/JoinRequestModal/JoinRequestModal";
import { fget, fpatch } from "../../../API/callsApi";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";
import { toast } from "react-hot-toast";
import { Info } from "phosphor-react";

const InvitationsPageOverview = () => {
  const { userData, currentGreeting } = useAuth();
  const [isJoinRequestModalOpen, setIsJoinRequestModalOpen] = useState({
    isOpen: false,
    data: {},
  });
  const [invitations, setInvitations] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [status, setStatus] = useState("all");

  useEffect(() => {
    getInvitations();
  }, [refreshCounter, status]);

  const getInvitations = async () => {
    const url =
      status === "all"
        ? `/organization/recieved-invites/`
        : `/organization/recieved-invites/?status=${status}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setInvitations(res?.data);
        setIsLoaded(true);
      }
    } catch (error) {
      console.log(error);
      setIsLoaded(true);
    }
  };

  const acceptInvitation = async (id) => {
    const acceptData = {
      url: `/organization/accept-invite/${id}/`,
      data: {
        accepted: true,
      },
    };
    try {
      const res = await fpatch(acceptData);
      if (res?.status === 200) {
        setRefreshCounter(refreshCounter + 1);
        toast.success("Successfully Accepted Invitation");
      }
    } catch (error) {
      toast.error("Error on Accepting Invitation");
    }
  };

  const rejectInvitation = async (id) => {
    const acceptData = {
      url: `/organization/accept-invite/${id}/`,
      data: {
        accepted: false,
      },
    };
    try {
      const res = await fpatch(acceptData);
      if (res?.status === 200) {
        toast.success("Successfully Rejected Invitation");
      }
    } catch (error) {
      toast.error("Error on rejecting Invitation");
    }
  };

  return (
    <Box>
      <Navbar />
      <Box sx={{ position: "absolute", top: "90px", width: "100%" }}>
        <Box>
          <HomePageBanner />
        </Box>
        <Box
          sx={{
            mx: 20,
            pt: 3,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ fontSize: "24px" }}>
              {currentGreeting},
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
              {userData?.first_name}
            </Typography>
          </Paper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pt: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Invitations
            </Typography>
            <FormControl sx={{ width: "180px" }}>
              <Select
                size="small"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ mt: 2 }}>
            {isLoaded ? (
              <>
                {invitations?.length ? (
                  <TableContainer
                    sx={{
                      border: "2px solid black",
                      width: "100%",
                      maxHeight: "400px",
                      overflowY: "auto",
                    }}
                  >
                    <Table sx={{ width: "100%", px: 2 }}>
                      <TableBody>
                        {invitations?.map((singleInvitation) => (
                          <TableRow
                            key={singleInvitation}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">
                              {singleInvitation?.org?.name ?? ""}
                            </TableCell>
                            <TableCell align="center">
                              <Typography>
                                <span style={{ fontSize: "10px" }}>
                                  Added By
                                </span>
                                {"  "}Owner 1
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <Typography>
                                {singleInvitation?.sent_date &&
                                  new Intl.DateTimeFormat("en-US", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }).format(
                                    new Date(singleInvitation?.sent_date)
                                  )}
                              </Typography>
                            </TableCell>

                            <TableCell align="center">
                              <Typography>
                                User -{" "}
                                {Number(
                                  singleInvitation?.org?.admin_count ?? 0
                                ) +
                                  Number(
                                    singleInvitation?.org?.user_count ?? 0
                                  )}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              {singleInvitation?.status === "Pending" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Box sx={{ width: "100px" }}>
                                    <FilledButton
                                      text="Accept"
                                      submit={() =>
                                        acceptInvitation(singleInvitation?.id)
                                      }
                                      /* setIsJoinRequestModalOpen({
                                      isOpen: true,
                                      data: singleInvitation,
                                    }) */
                                      bgColor="black"
                                      color="white"
                                    />
                                  </Box>
                                  <Box sx={{ width: "100px" }}>
                                    <OutlinedButton
                                      text="Reject"
                                      submit={() =>
                                        rejectInvitation(singleInvitation?.id)
                                      }
                                      color="black"
                                    />
                                  </Box>
                                </Box>
                              ) : (
                                <Typography>
                                  Invitation {singleInvitation?.status}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                      fontSize: "24px",
                      fontWeight: 500,
                      height: "250px",
                    }}
                  >
                    <Info size={50} />{" "}
                    <span>Currently you do not have any invitations</span>
                  </Typography>
                )}
              </>
            ) : (
              <CommonLoader color="black" height="250px" />
            )}
          </Box>
        </Box>
      </Box>
      <Modal
        open={isJoinRequestModalOpen?.isOpen}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setIsJoinRequestModalOpen({ isOpen: false, data: {} })}
      >
        <JoinRequestModal
          closeModal={() =>
            setIsJoinRequestModalOpen({ isOpen: false, data: {} })
          }
        />
      </Modal>
    </Box>
  );
};

export default InvitationsPageOverview;

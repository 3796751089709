import MainImg from "../../assets/ssomainimg.png";
import Grid from "@mui/material/Grid";
import { Typography } from "@material-ui/core";
import Navbar from "../Navbar/Navbar";
import React, { useState, useEffect } from "react";

const Header = () => {
  const [state, setState] = useState({
    mobileView: false,
  });

  const { mobileView } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1200
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const displayDesktop = () => {
    return (
      <div>
        <Navbar />
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5rem",
            paddingBottom: "3rem",
            paddingTop: "4rem",
            paddingLeft: "6rem",
            paddingRight: "30%",
          }}
        >
          <img src={MainImg} alt="mainImage" width="497.26" height="292" />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Poppins",
                fontSize: "44px",
                fontWeight: "400",
                lineHeight: "66px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Welcome to the <br />
              <b>Future of Filmaking</b>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const displayMobile = () => {
    return (
      <div>
        <Navbar />
        <Grid
          container
          pt={13}
          pb={4}
          style={{ backgroundColor: "black", color: "white" }}
        >
          <div style={{ display: "flex", padding: "2rem" }}>
            <img src={MainImg} alt="mainImage" width="497.26" height="292" />
            <Grid item pl={2} mt={3}>
              <Typography variant="h4">
                <p>
                  Welcome to the <br />
                  <b>Future of Filmaking</b>
                </p>
              </Typography>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  };

  return <div>{mobileView ? displayMobile() : displayDesktop()}</div>;
};

export default Header;

import { Button, Card, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import useStyles from "./InviteNewUserModal.style";

const InviteNewUserModal = ({ handleCheckUserExist }) => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      userEmail: "",
    },

    onSubmit: async (values) => {
      handleCheckUserExist(values.userEmail);
    },
  });
  return (
    <Card className={classes.card}>
      <Typography className={classes.header}>Invite New User</Typography>
      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "30px",
        }}
      >
        <TextField
          variant="standard"
          placeholder="User Mail Id"
          fullWidth
          type="email"
          name="userEmail"
          id="userEmail"
          InputProps={{ disableUnderline: true }}
          value={formik.values.userEmail}
          onChange={formik.handleChange}
          style={{
            border: "2px solid #000",
            padding: "5px",
            boxSizing: "border-box",
          }}
        />
        <div>
          <Button className={classes.actionBtn} type="submit">
            Add Email
          </Button>
          <Button
            className={classes.cancelBtn}
            // onClick={() => setIsNewUserModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default InviteNewUserModal;

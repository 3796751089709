import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    // minHeight: "350px",
    width: "532px",
    background: "#fff",
    padding: "33px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
  },
  actionBtn: {
    height: "44px",
    width: "160px",
    background: "#000",
    color: "#fff",
    marginRight: "12px",
    textTransform: "none",
    fontSize: "16px",
    "&:hover": {
      background: "#000",
      color: "#fff",
    },
  },
  cancelBtn: {
    height: "44px",
    width: "97px",
    background: "#fff",
    border: "2px solid #000",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Poppins",
  },

  header: {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    marginBottom: "0px",
  },
});

export default useStyles;

import React, { useEffect, useState } from "react";
import { Box, Modal, Paper, Typography } from "@mui/material";
import ManageServices from "../ManageServices/ManageServices";
import OrganizationDetails from "../OrganizationDetails/OrganizationDetails";
import LeaveOrganizationModal from "../LeaveOrganizationModal/LeaveOrganizationModal";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import NoOrganization from "../NoOrganization/NoOrganization";
import Navbar from "../../Navbar/Navbar";
import HomePageBanner from "../HomePageBanner/HomePageBanner";
import useAuth from "../../../hooks/useAuth";
import { fget } from "../../../API/callsApi";
import { toast } from "react-hot-toast";
import SingleAdminAlertModal from "../SingleAdminAlertModal/SingleAdminAlertModal";

const HomePageOverview = () => {
  const [isLeaveOrgModalOpen, setIsLeaveOrgModalOpen] = useState(false);
  const [view, setView] = useState("");
  const { userData, currentOrganization, currentGreeting } = useAuth();
  useEffect(() => {
    if (currentOrganization) {
      setView("services");
      getOrgDetails();
    }
  }, [currentOrganization]);

  const [organizationDetails, setOrganizationDetails] = useState({});
  const [isOrganizationDetailsLoaded, setIsOrganizationDetailsLoaded] =
    useState(true);
  const [isSingleAdminAlertOpen, setIsSingleAdminAlertOpen] = useState(false);

  const getOrgDetails = async () => {
    setIsOrganizationDetailsLoaded(false);
    const data = {
      url: `/organization/${currentOrganization}/detail/`,
    };
    try {
      const res = await fget(data);
      setOrganizationDetails(res?.data);
      setIsOrganizationDetailsLoaded(true);
    } catch (error) {
      console.log(error);
      setIsOrganizationDetailsLoaded(true);
    }
  };

  //In home component we show Organization banner and services details!
  return (
    <>
      <Navbar />
      <Box sx={{ position: "absolute", top: "90px", width: "100%" }}>
        <Box>
          {view === "services" ? (
            <OrganizationDetails
              organizationDetails={organizationDetails}
              isOrganizationDetailsLoaded={isOrganizationDetailsLoaded}
            />
          ) : (
            <HomePageBanner />
          )}
        </Box>
        <Box
          sx={{
            mx: 20,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            pt: 3,
          }}
        >
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Typography sx={{ fontSize: "24px" }}>
              {currentGreeting},
            </Typography>
            <Typography sx={{ fontWeight: 600, fontSize: "24px" }}>
              {userData?.first_name}
            </Typography>
          </Paper>
          <Box sx={{ width: "200px" }}>
            {view === "services" ? (
              <FilledButton
                text=" Leave Organization"
                submit={() => {
                  organizationDetails?.admin_count === 1
                    ? setIsSingleAdminAlertOpen(true)
                    : setIsLeaveOrgModalOpen(true);
                }}
                bgColor="black"
                color="white"
              />
            ) : null}
          </Box>
        </Box>
        <Box sx={{ mt: 4, mx: 20 }}>
          {view === "services" ? <ManageServices /> : <NoOrganization />}
        </Box>
        <Modal
          open={isLeaveOrgModalOpen}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClose={() => setIsLeaveOrgModalOpen(false)}
        >
          <LeaveOrganizationModal
            handleSubmit={() => {
              setIsLeaveOrgModalOpen(false);
              toast.success("removed just dummy");
            }}
            closeModal={() => setIsLeaveOrgModalOpen(false)}
            organizationDetails={organizationDetails}
          />
        </Modal>
        <Modal
          open={isSingleAdminAlertOpen}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClose={() => setIsSingleAdminAlertOpen(false)}
        >
          <SingleAdminAlertModal
            closeModal={() => setIsSingleAdminAlertOpen(false)}
          />
        </Modal>
      </Box>
    </>
  );
};

export default HomePageOverview;

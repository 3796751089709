import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Logo from "../../assets/logo.png";
import MainImage from "../../assets/mainimage.png";
import { fpatch } from "../../API/callsApi";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useStyles from "./styles";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

const theme = createTheme();

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState();
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });

  const classes = useStyles();

  const btnHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    let obj = {
      url: "/reset-password/",
      data: {
        email: email,
      },
    };

    fpatch(obj)
      .then((res) => {
        if (res.status === 200 ||res.status === 201) {
          setSnackValue({
            isOpen: true,
            message: "Email sent",
            isError: false,
          });
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setSnackValue({
          isOpen: true,
          message: "The email you entered is incorrect!",
          isError: true,
        });
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        component="main"
        sx={{
          height: "100vh",
        }}
      >
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={snackValue.isOpen}
          autoHideDuration={2000}
          onClose={() => setSnackValue({ ...snackValue, isOpen: false })}
          // message={`${snackValue.message}`}
        >
          <Alert
            severity={snackValue.isError === false ? "success" : "warning"}
          >
            {snackValue.message}
          </Alert>
        </Snackbar>
        <Grid
          container
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "45%",
          }}
        >
          <Grid>
            <div>
              <img src={Logo} alt="logo" width="151" height="40" />
            </div>
            <Typography
              sx={{
                pt: 4,
                pb: 2,
              }}
            >
              <span
                style={{
                  fontFamily: "Poppins, sans-serif",
                  fontSize: "32px",
                  fontWeight: "600",
                }}
              >
                Forgot Password?
              </span>
            </Typography>
            <span
              style={{
                fontFamily: "Poppins, sans-serif",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Dont worry enter your registered
              <br /> email below we will send you a<br /> reset link
            </span>
            <Grid
              noValidate
              sx={{
                mt: 2,
              }}
            >
              <form>
                <div>
                  <label htmlFor="email"> </label>
                  <input
                    className={classes.input}
                    style={{ width: "16.9rem" }}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="E-mail ID"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  className={classes.button}
                  onClick={btnHandler}
                >
                  {!loading && <span>Send password reset link</span>}
                  {loading && (
                    <CircularProgress
                      width="20"
                      height="20"
                      style={{ color: "white" }}
                    />
                  )}
                </button>
              </form>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={true}
          sx={{
            backgroundImage: `url(${MainImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default ForgotPassword;

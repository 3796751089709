import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    padding: "7px",
    margin: "9px 0",
    width: "17rem",
    border: "2px solid black",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
  },
  passwordInput: {
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    padding: "7px",
    border: "none",
    outline: "none",
  },
  button: {
    padding: "1px",
    margin: "9px 0",
    width: "18rem",
    backgroundColor: "#000000",
    color: "white",
    border: "none",
    height: "44px",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    cursor: "pointer",
  },
});

export default useStyles;

import React, { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import NotFound404 from "../assets/404.svg";

const NotFound = () => {
  return (
    <div
      style={{
        marginTop: "6rem",
        textAlign: "center",
        fontFamily: "Poppins,sans-serif",
      }}
    >
      <img src={NotFound404} width="300" height="200" alt="NotFound404" />
      <h1>Oops!</h1>
      <h2>404 Not Found</h2>
      <div className="error-details">
        <b>Sorry, an error has occured. Requested page not found!</b>
      </div>
      <br></br>
      <Link to="/" style={{ textDecoration: "none" }}>
        <Button variant="outlined">Take Me Home</Button>
      </Link>
    </div>
  );
};

export default NotFound;

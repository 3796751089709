import React, { useState, useRef, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import logo from "../../assets/icon.png";
import bg from "../../assets/bg1.png";
import { fpost, fpatch } from "../../API/callsApi";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import useStyles from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import { useHistory } from "react-router-dom";
import PasswordInput from "../CommonComponent/PasswordInput";
import { toast } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import { createCookie } from "../../authorization";
import PhoneInput, {
  isValidPhoneNumber,
  getCountryCallingCode,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

const Schema = yup.object().shape({
  fname: yup
    .string()
    .label("first_name")
    .matches(/^[a-zA-Z]+$/, "Only letters allowed")
    .max(15, "Maximum length 15")
    .required("This fiels is required!"),
  lname: yup
    .string()
    .label("last_name")
    .matches(/^[a-zA-Z]+$/, "Only letters allowed")
    .max(15, "Maximum length 15")
    .required("This fiels is required!"),
  username: yup.string().required("This fiels is required!"),
  code: yup.string().required("This fiels is required!"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("This fiels is required!"),
  password: yup
    .string()
    .required("This fiels is required!")
    .min(8, "Minimum eight characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "At least one letter, one number and one special character"
    ),
  // confirmpassword: yup.string().when("password", {
  //   is: (val) => (val && val.length > 0 ? true : false),
  //   then: yup
  //     .string()
  //     .oneOf([yup.ref("password")], "Both password need to be the same"),
  // }),
  confirmpassword: yup
    .string()
    .required("Passwords does not match")
    .test("passwords-match", "Passwords don't match", function (value) {
      return this.parent.password === value;
    }),
});

export default function Signup(props) {
  const phoneRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackValue, setSnackValue] = useState({
    isOpen: false,
    message: "",
    isError: false,
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");

  const classes = useStyles();
  const history = useHistory();
  const { loadUserData } = useAuth();
  let inviteId = null;

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const updateInvitation = async (id) => {
    const acceptData = {
      url: `/organization/invites/${id}/`,
    };
    try {
      const res = await fpatch(acceptData);
      if (res?.status === 200 || res?.status === 201) {
        setLoading(false);
        if (res) {
          setTimeout(() => history.push("/"), 2000);
        }
      }
    } catch (error) {
      toast.error("error occured on updating invitation");
      setLoading(false);
    }
  };

  const refreshToken = async (email, password) => {
    let formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);

    let obj = {
      url: "/api/token/",
      data: formdata,
    };
    try {
      const res = await fpost(obj);
      if (res) {
        let refreshToken = res.data.refresh;
        let accessToken = res.data.access;
        localStorage.setItem("refresh", refreshToken);
        localStorage.setItem("access", accessToken);

        let rToken = localStorage.getItem("refresh");
        let aToken = localStorage.getItem("access");
        createCookie("access", aToken);
        createCookie("refresh", rToken);
        loadUserData(accessToken);

        toast.success("Successfully logged in");
        return Promise.resolve(res);
      }
    } catch (error) {
      setLoading(false);
      console.log("error", error.response.data);
      if (error.response.data.detail === "Incorrect Password!") {
        toast.error("Incorrect Username/Password");
      } else if (
        error.response.data.detail ===
        "No active account found with the given credentials"
      ) {
        toast.error("You seem new here. Have you registered?");
      } else {
        toast.error("Oops, something went wrong. Try again");
      }
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    if (phoneRef && phoneRef.current) {
      phoneRef.current.style.height = "40px";
      phoneRef.current.style.paddingLeft = "10px";
    }
  }, [phoneRef]);

  return (
    <Grid container component="main" sx={{ height: "100vh" }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={snackValue.isOpen}
        autoHideDuration={2000}
        onClose={() => setSnackValue({ ...snackValue, isOpen: false })}
      >
        <Alert severity={snackValue.isError === false ? "success" : "warning"}>
          {snackValue.message}
        </Alert>
      </Snackbar>
      <CssBaseline />
      <Grid
        item
        xs={true}
        sx={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid
        item
        xs={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid>
          <img src={logo} alt="logo" />
          <h2 style={{ marginTop: "2rem", marginBottom: 0 }}>
            <b> Sign Up </b>
          </h2>
          <Grid
            noValidate
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Formik
              initialValues={{
                fname: "",
                lname: "",
                code: "+91",
                phone_number: "",
                email: "",
                username: "",
                password: "",
                confirmpassword: "",
              }}
              validationSchema={Schema}
              onSubmit={async (values, { setSubmitting, setFieldError }) => {
                setLoading(true);
                let formdata = new FormData();
                formdata.append("first_name", values.fname);
                formdata.append("last_name", values.lname);
                if (isValidPhoneNumber(phoneNumber)) {
                  formdata.append("code", getCountryCallingCode(country));
                  formdata.append("phone_number", phoneNumber);
                }
                formdata.append("email", values.email);
                formdata.append("nickname", values.username);
                formdata.append("password", values.password);

                let obj = {
                  url: "/signup/",
                  data: formdata,
                };

                fpost(obj)
                  .then(async (res) => {
                    if (res.status === 200 || res.status === 201) {
                      setSnackValue({
                        isOpen: true,
                        message: `SuccessFully Registered! Taking you to login page...`,
                        isError: false,
                      });
                      setLoading(false);
                      const queryParams = new URLSearchParams(
                        props.location.search
                      );
                      inviteId = queryParams.has("invite")
                        ? queryParams.get("invite")
                        : null;
                      if (inviteId && res.data.email !== "") {
                        setLoading(true);
                        refreshToken(res.data.email, values.password)
                          .then((res) => {
                            if (res.data.access && res.data.refresh) {
                              updateInvitation(inviteId);
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      }
                      if (!inviteId) {
                        !loading && setTimeout(() => history.push("/"), 2000);
                      }
                    }
                  })
                  .catch((error) => {
                    setLoading(false);
                    setSnackValue({
                      isOpen: true,
                      message: "oops, something went wrong, try again",
                      isError: true,
                    });
                    if (error.response.status === 400) {
                      const errorFields = Object.keys(error.response.data);
                      errorFields.forEach((field) => {
                        setFieldError(field, error.response.data[field]);
                      });
                    }
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ errors, touched, values }) => (
                <Form>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", position: "relative" }}>
                      <Field
                        name="fname"
                        className={classes.input}
                        placeholder="First Name"
                      />
                      {errors.fname && touched.fname ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "3.2rem",
                          }}
                        >
                          <span>{errors.fname}</span>
                        </div>
                      ) : null}
                    </div>

                    <div style={{ display: "flex", position: "relative" }}>
                      <Field
                        name="lname"
                        className={classes.input}
                        placeholder="Last Name"
                      />
                      {errors.lname && touched.lname ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "3.2rem",
                          }}
                        >
                          <span>{errors.lname}</span>
                        </div>
                      ) : null}
                    </div>

                    <div style={{ display: "flex" }}>
                      <div style={{ display: "flex", position: "relative" }}>
                        <PhoneInput
                          placeholder="Enter phone number"
                          value={phoneNumber}
                          onChange={setPhoneNumber}
                          style={{
                            width: "18rem",
                            height: "50px",
                          }}
                          ref={phoneRef}
                          onCountryChange={(value) => {
                            setCountry(value);
                          }}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", position: "relative" }}>
                      <Field
                        name="email"
                        className={classes.input}
                        placeholder="Email"
                      />
                      {errors.email && touched.email ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "3.2rem",
                          }}
                        >
                          <span>{errors.email}</span>
                        </div>
                      ) : null}
                    </div>

                    <div style={{ display: "flex", position: "relative" }}>
                      <Field
                        name="username"
                        className={classes.input}
                        placeholder="Username"
                      />
                      {errors.username && touched.username ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "3.2rem",
                          }}
                        >
                          <span>{errors.username}</span>
                        </div>
                      ) : null}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        position: "relative",
                      }}
                    >
                      <PasswordInput
                        input={
                          <Field
                            name="password"
                            type={showPassword ? "text" : "password"}
                            className={classes.passwordInput}
                            placeholder="Password"
                            autoComplete="off"
                          />
                        }
                        toggleShowPassword={toggleShowPassword}
                        showPassword={showPassword}
                        password={values.password}
                      />
                      {errors.password && touched.password ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            // top: "2.1 rem",
                            bottom: "-0.6rem",
                            letterSpacing: "0",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <span>{errors.password}</span>
                        </div>
                      ) : null}
                    </div>

                    <div style={{ display: "flex", position: "relative" }}>
                      <Field
                        name="confirmpassword"
                        type="password"
                        className={classes.input}
                        placeholder="Confirm Password"
                        autoComplete="off"
                      />
                      {errors.confirmpassword && touched.confirmpassword ? (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            position: "absolute",
                            top: "3.2rem",
                          }}
                        >
                          <span>{errors.confirmpassword}</span>
                        </div>
                      ) : null}
                    </div>
                    <button type="submit" className={classes.button}>
                      {!loading && <span>Sign Up</span>}
                      {loading && (
                        <CircularProgress
                          width="20"
                          height="20"
                          style={{ color: "white" }}
                        />
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Grid>
          <Grid item>
            <Link
              href="/"
              variant="body2"
              style={{
                color: "black",
                fontSize: "16px",
                fontFamily: "Poppins, sans-serif",
              }}
            >
              {"Already Registered? Log In"}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

import { Button } from "@mui/material";
import React from "react";

export const FilledButton = ({ text, submit, bgColor, color }) => {
  return (
    <Button
      style={{
        width: "100%",
        backgroundColor: bgColor,
        color: color,
        borderRadius: "0px",
        height: "40px",
        "&:hover": {
          backgroundColor: "gray",
          color: color,
        },
      }}
      onClick={submit}
      disableElevation
    >
      {text}
    </Button>
  );
};
export const OutlinedButton = ({ text, color, submit }) => {
  return (
    <Button
      style={{
        width: "100%",
        border: "2px solid black",
        height: "40px",
        color: color,
        borderRadius: "0px",
        "&:hover": {
          backgroundColor: "gray",
          color: color,
        },
      }}
      onClick={submit}
      disableElevation
    >
      {text}
    </Button>
  );
};

import { Button, Box, Card } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { fget } from "../../../API/callsApi";
import CloseIcon from "@mui/icons-material/Close";
import DefaultImg from "../../../assets/defaultimg.png";
import Trash from "../../../assets/Trash.png";
import Manage from "../../../assets/Manage.png";
import useStyles from "./UserInfoModal.styles";

const UserInfoModal = ({
  user,
  closeModal,
  handleManageRolesModal,
  handleDeleteUserModal,
}) => {
  const classes = useStyles();
  const [services, setServices] = useState([]);

  const getOrgInfo = async () => {
    const serviceStr = user?.services
      .map((eachService) => `service=${eachService}`)
      .join("&");
    const url = `/service/bulk/?${serviceStr}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setServices(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrgInfo();
  }, []);

  return (
    <Card className={classes.card}>
      <Box className={classes.contentOne}>
        <p style={{ marginBottom: "0px", marginTop: "0px" }}>User Info</p>
        <Button
          style={{ minWidth: "0px", padding: "5px" }}
          onClick={closeModal}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box className={classes.contentTwo}>
        <div>
          {user?.avatar ? (
            <img src={user?.avatar} alt="profilepic" width="128" height="128" />
          ) : (
            <img src={DefaultImg} alt="profilepic" width="128" height="128" />
          )}
        </div>
        <div className={classes.contentTwoRight}>
          <div>
            <p className={classes.infoTypeOne}>User Name</p>
            <p className={classes.infoTypeTwo}>
              {user?.first_name} {user?.last_name}
            </p>
          </div>
          <div>
            <p className={classes.infoTypeOne}>Services</p>
            <p className={classes.infoTypeTwo}>
              {services?.map((service, index) => {
                return (
                  <span key={service?.id}>
                    {service?.name}
                    {services?.length - 1 == index ? "" : ","}
                  </span>
                );
              })}
            </p>
          </div>
        </div>
      </Box>
      <Box>
        <p className={classes.infoTypeOne}>Mail-ID</p>
        <p style={{ marginBottom: "0px", marginTop: "0px" }}>{user?.email}</p>
      </Box>
      <Box className={classes.cardContentThree}>
        <div>
          <p className={classes.infoTypeOne}>user Role</p>
          <p style={{ marginTop: "0px", marginBottom: "0px" }}>{user?.role}</p>
        </div>
        <div>
          <p className={classes.infoTypeOne}>Joining Date</p>
          <p style={{ marginTop: "0px", marginBottom: "0px" }}>
            {user?.created_at &&
              new Date(user?.created_at).toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
          </p>
        </div>
      </Box>
      <Box className={classes.cardContentfour}>
        <Button
          style={{ border: "2px solid #000000", fontFamily: "Poppins" }}
          startIcon={<img src={Manage} alt="icons" />}
          onClick={handleManageRolesModal}
        >
          Modify User
        </Button>
        <Button
          style={{ border: "2px solid #F03333", fontFamily: "Poppins" }}
          startIcon={<img src={Trash} alt="icons" />}
          onClick={handleDeleteUserModal}
        >
          Remove User
        </Button>
      </Box>
    </Card>
  );
};

export default UserInfoModal;

import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import * as React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useHistory, useLocation } from "react-router-dom";
import { fpost } from "../../API/callsApi";
import Logo from "../../assets/logo.png";
import BGImage2 from "../../assets/mainimage.png";
import { createCookie, readCookie } from "../../authorization";
import useAuth from "../../hooks/useAuth";
import PasswordInput from "../CommonComponent/PasswordInput";
import useStyles from "./styles";

const theme = createTheme();

const Login = (props) => {
  // let params = useParams();
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { loadUserData } = useAuth();
  const queryParams = new URLSearchParams(useLocation().search);
  const redirectUrl = queryParams.get("redirectUrl");
  const domainType = queryParams.get("type");
  const baseDomain = queryParams.get("baseUrl");

  useEffect(() => {
    if (props?.clearLocalStorage) {
      localStorage.clear();
    } else {
      let data = readCookie("refresh");
      let data2 = readCookie("access");

      // let slug = params.slug;

      if (!redirectUrl && data) {
        // history.push(data ? "/home" : "");
        history.push("/home");
      } else if (redirectUrl && domainType === "sameDomain" && data && data2) {
        window.location.href = `${
          redirectUrl?.includes("localhost:") ? "http" : "https"
        }://${redirectUrl}`;
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    onSubmit: (values) => {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("password", values.password);

      let obj = {
        url: "/api/token/",
        data: formdata,
      };
      fpost(obj)
        .then((res) => {
          if (res) {
            let refreshToken = res.data.refresh;
            let accessToken = res.data.access;
            localStorage.setItem("refresh", refreshToken);
            localStorage.setItem("access", accessToken);

            let rToken = localStorage.getItem("refresh");
            let aToken = localStorage.getItem("access");

            createCookie("access", aToken);
            createCookie("refresh", rToken);
            loadUserData(accessToken);

            toast.success("Successfully logged in");
            // let slug = params.slug;
            if (!redirectUrl && !domainType && !baseDomain) {
              history.push("/home");
            } else {
              window.location.href =
                domainType === "crossDomain"
                  ? `${
                      redirectUrl?.includes("localhost:") ? "http" : "https"
                    }://${baseDomain}/auth?idToken=${
                      res.data.auth_token
                    }&redirectUrl=${redirectUrl}`
                  : redirectUrl;
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.data.detail === "Incorrect Password!") {
            toast.error("Incorrect Username/Password");
          } else if (
            error.response.data.detail ===
            "No active account found with the given credentials"
          ) {
            toast.error("You seem new here. Have you registered?");
          } else {
            toast.error("Oops, something went wrong. Try again");
          }
        });
    },
  });

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        sx={{
          height: "100vh",
        }}
      >
        <Grid
          container
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "45%",
          }}
        >
          <Grid>
            <div>
              <img src={Logo} alt="logo" width="151" height="40" />
            </div>
            <Typography
              component="h1"
              variant="h5"
              sx={{
                pt: 3,
                fontFamily: "Poppins, sans-serif",
                fontSize: "32px",
                fontWeight: "600",
              }}
            >
              Log In
            </Typography>
            <Grid
              noValidate
              sx={{
                mt: 2,
              }}
            >
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <label htmlFor="username"> </label>
                  <input
                    className={classes.input}
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    required
                  />
                </div>
                <PasswordInput
                  input={
                    <input
                      className={classes.passwordInput}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      placeholder="Password"
                      onChange={formik.handleChange}
                      required
                    />
                  }
                  toggleShowPassword={toggleShowPassword}
                  showPassword={showPassword}
                  password={formik.values.password}
                />
                <Grid item mb={2}>
                  <Link
                    href="/forgotpassword"
                    variant="body2"
                    style={{
                      color: "black",
                      fontSize: "16px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    {"Forgot Password ?"}
                  </Link>
                </Grid>
                <button type="submit" className={classes.button}>
                  {!loading && <span>Log In</span>}
                  {loading && (
                    <CircularProgress
                      width="20"
                      height="20"
                      style={{ color: "white" }}
                    />
                  )}
                </button>
              </form>

              <Grid item>
                <Link
                  href="/signup"
                  variant="body2"
                  style={{
                    color: "black",
                    fontSize: "16px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={true}
          sx={{
            backgroundImage: `url(${BGImage2})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </ThemeProvider>
  );
};

export default Login;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  card: {
    height: "540px",
    width: "532px",
    background: "#fff",
    padding: "30px",
    gap: "30px",
    fontFamily: "Poppins",
  },
  cardHeader: {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    textAlign: "center",
    marginBottom: "30px",
  },
  radioSelect: {
    width: "530px",
    height: "44px",
    border: "2px solid #000",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "30px",
    padding: "10px 0px 10px 10px",
    boxSizing: "border-box",
    fontSize: "18px",
  },
  secondHeader: {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    textAlign: "center",
    margin: "0px",
    marginBottom: "10px",
  },
  actionBtn: {
    height: "44px",
    width: "160px",
    background: "#000",
    color: "#fff",
    marginRight: "12px",
    textTransform: "none",
    fontSize: "16px",
    "&:hover": {
      background: "#000",
      color: "#fff",
    },
  },
  cancelBtn: {
    height: "44px",
    width: "97px",
    background: "#fff",
    border: "2px solid #000",
    textTransform: "none",
    fontSize: "16px",
    fontFamily: "Poppins",
  },
  //newUserModal
  cardTwo: {
    minHeight: "350px",
    width: "532px",
    background: "#fff",
    padding: "33px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "30px",
  },
  header: {
    fontSize: "24px",
    fontWeight: "600",
    fontFamily: "Poppins",
    marginBottom: "0px",
  },
  divContent: {
    width: "100%",
    height: "44px",
    padding: "10px",
    border: "2px solid #000",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default useStyles;

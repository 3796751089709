import React from "react";
import { NavLink } from "react-router-dom";
import Grid from "@mui/material/Grid";
import useStyles from "./styles";
// import { readCookie, fget } from "../../API/callsApi";
import Header from "../Header/Header";
import DefaultImg from "../../assets/defaultimg.png";
import useAuth from "../../hooks/useAuth";

const ProfileCard = () => {
  const [image, setImage] = React.useState();
  const { userData } = useAuth();
  const classes = useStyles();

  React.useEffect(() => {
    setImage(userData?.data?.avatar);
  }, [userData]);
  return (
    <div>
      <Header />

      <Grid container p={3}>
        <Grid container item style={{ display: "grid", placeItems: "center" }}>
          <Grid item className={classes.center}>
            <Grid item py={2}>
              <div>
                {image ? (
                  <img src={image} alt="profilepic" width="107" height="107" />
                ) : (
                  <img
                    src={DefaultImg}
                    alt="profilepic"
                    width="107"
                    height="107"
                  />
                )}
              </div>
            </Grid>
            <span className={classes.heading}>
              {userData?.first_name} {userData?.last_name}
            </span>
            <span className={classes.text}>{userData?.email}</span>
            <hr className={classes.hr} />
            <Grid item pt={2}>
              <span className={classes.heading}>Username</span>
            </Grid>
            <span className={classes.text}>{userData?.nickname}</span>
            <hr className={classes.hr} />
            <Grid item pt={2}>
              <span className={classes.heading}>Phone</span>
            </Grid>
            <span className={classes.text}>{userData?.phone_number?.raw}</span>
            <hr className={classes.hr} />
            <Grid item pt={2}>
              <NavLink to="/editprofile" style={{ textDecoration: "none" }}>
                <button className={classes.button}>Edit Profile</button>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileCard;

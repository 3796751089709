import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import React from "react";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";

const DeleteModal = ({
  handleDelete,
  closeModal,
  text1,
  text2,
  btnText,
  isRemoving,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        height: "200px",
        width: "500px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center", fontWeight: 600 }}>
          Delete Confirmation
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: text1 ? "space-between" : "center",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {text1}
          </Typography>
          <Typography>{text2}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "20px" }}>
            <Box sx={{ width: "180px" }}>
              {isRemoving ? (
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "red",
                    borderRadius: "0px",
                    height: "40px",
                  }}
                  disableElevation
                >
                  <CircularProgress style={{ color: "white" }} />
                </Button>
              ) : (
                <FilledButton
                  text={btnText}
                  submit={handleDelete}
                  bgColor="red"
                  color="white"
                />
              )}
            </Box>
            <Box sx={{ width: "120px" }}>
              <OutlinedButton text="cancel" submit={closeModal} color="black" />
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DeleteModal;

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  input: {
    padding: "5px",
    margin: "9px 0",
    width: "13rem",
    border: "2px solid black",
  },

  center: {
    display: "grid",
    placeItems: "center",
    padding: "2rem",
    border: "2px solid black",
  },

  button: {
    padding: "5px",
    margin: "9px 0",
    width: "13rem",
    backgroundColor: "#000000",
    color: "white",
    border: "none",
    height: "44px",
    fontSize: "16px",
    fontFamily: "Poppins, sans-serif",
    cursor: "pointer",
  },

  hr: {
    width: "320px",
    borderTop: "2px solid black",
  },

  scroll: {
    overflow: "auto",
  },

  heading: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "24px",
    fontWeight: "600",
  },

  text: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "16px",
  },
});

export default useStyles;

import { AppBar, Toolbar, Button, IconButton, Drawer } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import VLogo from "../../assets/VigaLogoWhite.png";
import Profile from "../../assets/Profile.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { deleteCookies } from "../../authorization";
import useStyles from "./styles";
import { Box } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-hot-toast";

export default function Navbar() {
  const {
    isUserDataLoading,
    organizationsList,
    currentOrganization,
    setCurrentOrganization,
    isAdmin,
  } = useAuth();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const history = useHistory();

  const { mobileView, drawerOpen } = state;

  const classes = useStyles();

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1100
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  const logoutBtn = () => {
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
    deleteCookies();
    toast.success("Logged out successfully");
  };

  const displayDesktop = () => {
    return (
      <>
        <Box sx={{ mx: 20 }} className={classes.toolbar}>
          <img src={VLogo} alt="logo" onClick={() => history.push("/home")} />
          {!isUserDataLoading && (
            <Box sx={{ ml: 4 }}>
              {organizationsList?.length ? (
                <select
                  value={currentOrganization}
                  onChange={(e) => {
                    setCurrentOrganization(e?.target?.value);
                    localStorage.setItem(
                      "currentOrganization",
                      e?.target?.value
                    );
                  }}
                  className={classes.orgDropdown}
                >
                  {organizationsList?.map((singleOrganization) => {
                    return (
                      <option
                        value={singleOrganization?.id}
                        key={singleOrganization?.id}
                        className={classes.dropdownOption}
                      >
                        {singleOrganization?.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                ""
              )}
              {isAdmin ? (
                <>
                  <NavLink
                    to="/home"
                    activeClassName={classes.active}
                    className={classes.noborder}
                  >
                    Home
                  </NavLink>

                  <NavLink
                    to="/organizations"
                    activeClassName={classes.active}
                    className={classes.noborder}
                  >
                    Organizations
                  </NavLink>
                </>
              ) : (
                ""
              )}
              <NavLink
                to="/invitations"
                activeClassName={classes.active}
                className={classes.noborder}
              >
                Invitations
              </NavLink>
            </Box>
          )}

          <div
            style={{
              marginLeft: "auto",
            }}
          >
            {!isUserDataLoading && (
              <NavLink
                to="/profile"
                activeClassName={classes.active}
                className={classes.noborder}
              >
                <Button className={classes.noborder}>
                  <img src={Profile} alt="Profile Menu Icon" /> Profile
                </Button>
              </NavLink>
            )}
            <NavLink
              to="/"
              exact
              activeClassName={classes.active}
              className={classes.noborder}
            >
              <Button className={classes.border} onClick={logoutBtn}>
                <LogoutIcon></LogoutIcon>
                Log Out
              </Button>
            </NavLink>
          </div>
        </Box>
      </>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img src={VLogo} alt="logo" />
        <IconButton
          {...{
            edge: "end",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
          <MenuIcon />
        </IconButton>

        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={classes.drawerContainer}>
            <NavLink
              to="/home"
              activeClassName={classes.active}
              className={classes.mobMenu}
            >
              Home
            </NavLink>

            <div style={{ display: "grid" }}>
              <NavLink
                to="/profile"
                activeClassName={classes.active}
                className={classes.mobMenu}
              >
                Profile
              </NavLink>
              <NavLink
                to="/"
                exact
                activeClassName={classes.active}
                className={classes.mobMenu}
                onClick={logoutBtn}
              >
                Log Out
              </NavLink>
            </div>
          </div>
        </Drawer>
      </Toolbar>
    );
  };

  return (
    <header>
      <AppBar className={classes.header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
    </header>
  );
}
